import { put, call } from 'redux-saga/effects';
import getDownloadMaps from '../services/downloadMapService';
import {
  DOWNLOAD_MAPS_LOADED,
  DOWNLOAD_MAPS_FAILED,
  DOWNLOAD_MAP_SHOW_LOADING,
  DOWNLOAD_MAP_HIDE_LOADING
} from '../constants';

export default function* fetchDownloadMaps(data) {
  try {
    yield put({ type: DOWNLOAD_MAP_SHOW_LOADING });
    const response = yield call(getDownloadMaps, data);
    yield put({ type: DOWNLOAD_MAPS_LOADED, response });
    yield put({ type: DOWNLOAD_MAP_HIDE_LOADING });
  } catch (error) {
    yield put({ type: DOWNLOAD_MAPS_FAILED, error });
    yield put({ type: DOWNLOAD_MAP_HIDE_LOADING });
  }
}
