import axios from 'axios';
import { apiMiddleware } from '../utility/apiMiddleware';
import { appendTenant } from '../utility/appendTenant';
import { GET_MAP_DOWNLOAD } from './apis';

const getDownloadMaps = async ({ data }) => {
  try {
    //const { data } = await axios.get(`${GET_MAP_DOWNLOAD}${countryCode}?tenant=${ localStorage.getItem('tenantHandle') || ''}`);
    const url = GET_MAP_DOWNLOAD + data.region; //+ data.mapType + '/'
    const response = await apiMiddleware(
      //`${GET_MAP_DOWNLOAD}${countryCode}?tenant=${localStorage.getItem('tenantHandle') || ''}`,
      appendTenant(url, ['tenant']),
      'get'
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export default getDownloadMaps;
