import axios from 'axios';
import { store } from '..';
import { apiMiddleware } from '../utility/apiMiddleware';
import { appendTenant } from '../utility/appendTenant';
import { getTenantDetails, GET_USERS, GET_USER_ROLES } from './apis';

export const getUsers = async () => {
  try {
    //const { data, status } = await axios.get(GET_USERS + localStorage.getItem('tenantHandle'));
    const response = await apiMiddleware(GET_USERS + getTenantDetails(), 'get');
    if (response.statusCode === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

export const getUserRoles = async () => {
  try {
    //const { data, status } = await axios.get(GET_USER_ROLES + localStorage.getItem('tenantHandle'));
    const response = await apiMiddleware(GET_USER_ROLES + getTenantDetails(), 'get');
    if (response.statusCode === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};
