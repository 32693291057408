import * as types from '../constants';
import { decryptFromHex } from '../common/utils/crypto';

const initialState = {
  isLoggedIn: false,
  claims: [],
  user: {},
  tenant: {},
  isLoading: false,
  isLoginFailed: false,
  userRole: 'default',
  semType: localStorage.sem,
  vceSemType: null,
  isResetPasswordFailed: false,
  isResetPasswordLoading: false,
  isPageLoading: false
};

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        const queryValue = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return queryValue;
      }, {})
    : {};
};

const decodeURL = location => {
  let url = '';
  try {
    url = decryptFromHex(location);
  } catch (err) {
    url = '#/sems/dashboard';
  }
  return url;
};

export default function(state = initialState, action) {
  const { response } = action;
  //let response = action.response;
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      let vceSemType = 'sem1';
      let semType = 'sem1';
      localStorage.setItem('tenant', response.currentTenant.name);
      localStorage.setItem('tenantHandle', response.currentTenant.handle);
      localStorage.setItem('user', response.tokenValidation.username);
      localStorage.setItem('userId', response.tokenValidation.useridentity);
      localStorage.setItem('id', response.tokenValidation.id);
      //When sem is null
      if (response.tokenValidation.sem === null) {
        semType = 'sem1';
        vceSemType = 'sem1';
        localStorage.setItem('sem', 'sem1');
        localStorage.setItem('switchIndex', '0');
        localStorage.setItem('semType', 'sem1');
      }
      //When sem is sem1
      else if (response.tokenValidation.sem === 'sem1') {
        semType = 'sem1';
        vceSemType = 'sem1';
        localStorage.setItem('sem', 'sem1');
        localStorage.setItem('semType', 'sem1');
        localStorage.setItem('switchIndex', '0');
      }
      //When sem is sem2
      else if (response.tokenValidation.sem === 'sem2') {
        semType = 'sem2';
        vceSemType = 'sem2';
        localStorage.setItem('sem', 'sem2');
        localStorage.setItem('semType', 'sem2');
        if (response.currentTenant.handle === 'gtt') {
          localStorage.setItem('switchIndex', '1');
        } else {
          localStorage.setItem('switchIndex', '0');
        }
      }
      //When sem is sem2.5
      else if (response.tokenValidation.sem === 'sem2.5') {
        semType = 'sem2.5';
        vceSemType = 'sem2.5';
        localStorage.setItem('sem', 'sem2.5');
        localStorage.setItem('semType', 'sem2.5');
        if (response.currentTenant.handle === 'gtt') {
          localStorage.setItem('switchIndex', '2');
        } else if (response.currentTenant.handle === 'rt') {
          localStorage.setItem('switchIndex', '1');
        }
      }
      //Check for vce
      else {
        if (response.currentTenant.handle === 'vce') {
          semType = 'sem2';
          localStorage.setItem('sem', 'sem2');
          if (response.tokenValidation.sem === 'SID2') {
            localStorage.setItem('semType', 'SID2');
            localStorage.setItem('switchIndex', '1');
            vceSemType = 'SID2';
          } else {
            localStorage.setItem('semType', 'ICM1');
            localStorage.setItem('switchIndex', '2');
            vceSemType = 'ICM1';
          }
        }
      }
      // setting sem2 for specific tenants
      // const setSemForVbc =
      //   response.currentTenant.handle == 'vbc' ||
      //   response.currentTenant.handle == 'vtna' ||
      //   response.currentTenant.handle == 'mack'
      //     ? (localStorage.setItem('sem', 'sem2'), localStorage.setItem('semType', 'sem2'))
      //     : '';

      //Specific case for vbc
      if (response.currentTenant.handle === 'vbc') {
        semType = response.tokenValidation.sem;
        if (semType === 'sem1' || semType === 'sem2') {
          localStorage.setItem('sem', 'sem2');
          localStorage.setItem('semType', 'sem2');
          localStorage.setItem('switchIndex', '0');
          semType = 'sem2';
          vceSemType = 'sem2';
        } else {
          localStorage.setItem('sem', 'sem2.5');
          localStorage.setItem('semType', 'sem2.5');
          localStorage.setItem('switchIndex', '1');
          semType = 'sem2.5';
          vceSemType = 'sem2.5';
        }
      }

      //Specific case for vtna, mack
      if (response.currentTenant.handle === 'vtna' || response.currentTenant.handle === 'mack') {
        localStorage.setItem('sem', 'sem2.5');
        localStorage.setItem('semType', 'sem2.5');
        semType = 'sem2.5';
        vceSemType = 'sem2.5';
      }

      //Specific case for penta
      if (response.currentTenant.handle === 'penta') {
        localStorage.setItem('sem', 'TAD1');
        localStorage.setItem('semType', 'TAD1');
        semType = 'TAD1';
        vceSemType = 'TAD1';
      }

      // eslint-disable-next-line prefer-object-spread
      Object.assign(state, {
        claims: response.claims,
        user: response.tokenValidation,
        isLoggedIn: true,
        tenant: response.currentTenant,
        isLoading: false,
        isLoginFailed: false,
        semType,
        vceSemType
      });

      // eslint-disable-next-line no-case-declarations
      const res = decodeURL(getQueryStringParams(window.location.hash));
      window.location.href = res;
      return state;
      break;

    case types.UPDATE_CREDENTIALS:
      // let defaultSemType = 'sem1';
      // const data = action.data;
      //localStorage.setItem('claims', data.claims);
      // response = action.response;

      // if (data.tokenValidation.sem === null) {
      //   defaultSemType = 'sem1';
      // } else if (data.tokenValidation.sem === 'sem1') {
      //   defaultSemType = 'sem1';
      // } else {
      //   defaultSemType = data.tokenValidation.sem;
      // }

      const reloadedClaims = localStorage.getItem('claims');
      const reloadedTenant = {
        handle: localStorage.getItem('tenantHandle'),
        name: localStorage.getItem('tenant')
      };
      const reloadedUser = {
        username: localStorage.getItem('user'),
        useridentity: localStorage.getItem('userId'),
        id: localStorage.getItem('id')
      };
      const reloadedSemType = localStorage.getItem('sem');
      const reloadedVceSemType = localStorage.getItem('semType');

      Object.assign(state, {
        claims: reloadedClaims,
        user: reloadedUser,
        isLoggedIn: true,
        tenant: reloadedTenant,
        isLoading: false,
        isLoginFailed: false,
        semType: reloadedSemType,
        vceSemType: reloadedVceSemType
      });

      // setting sem2 for specific tenants
      // const setSemForVbc =
      //   response.currentTenant.handle == 'vbc' ||
      //   response.currentTenant.handle == 'vtna' ||
      //   response.currentTenant.handle == 'mack'
      //     ? localStorage.setItem('sem', 'sem2')
      //     : '';

      // eslint-disable-next-line prefer-object-spread
      // Object.assign(state, {
      //   claims: data.claims,
      //   user: data.tokenValidation,
      //   isLoggedIn: true,
      //   tenant: data.currentTenant,
      //   isLoading: false,
      //   isLoginFailed: false,
      //   semType: defaultSemType
      // });
      return state;
      break;

    case types.SHOW_LOADING:
      return { ...state, isLoading: true };
    case types.HIDE_LOADING:
      return { ...state, isLoading: false };
    case types.SET_USER_ROLE:
      //console.log('inside user role ', action);
      localStorage.setItem('userRole', action.role);
      return { ...state, userRole: action.role };

    // case types.LOGOUT:
    //   localStorage.clear();
    //   window.location.href = '#/login';
    //   return { isLoggedIn: false, isLoginFailed: false };

    case types.LOGIN_ERROR:
      return { ...state, isLoggedIn: false, isLoading: false, isLoginFailed: true };

    case types.UPDATE_SEMS_TYPE:
      return { ...state, semType: action.data.sem, vceSemType: action.data.semType };
    case types.SHOW_PAGE_LOADING:
      return { ...state, isPageLoading: true };
    case types.HIDE_PAGE_LOADING:
      return { ...state, isPageLoading: false };
    default:
      return state;
  }
}
