import React from 'react';
import * as types from '../constants';

const getApplicationReports = response => {
  let list = [];
  if (response) {
    list = response.filter(item => {
      return item.appType === 'app';
    });
  }
  return { list };
};

const getFirmWareReports = response => {
  let list = [];
  if (response) {
    list = response.filter(item => {
      return item.appType === 'firmware';
    });
  }
  const columns = [
    { name: 'Firmware name', selector: 'firmwareName', sortable: true },
    { name: 'Installed*', selector: 'noOfInstalledVehicleFirware', sortable: true },
    { name: '% of Installation', selector: 'percentageOfInstallationFW', sortable: true },
    { name: 'Vehicles', selector: 'totalNoOfVehicleFW', sortable: true },
    { name: 'Export Failure', selector: 'firmwareName', sortable: true, cell: row => <div /> },
    { name: 'Export All', selector: 'firmwareName', sortable: true, cell: row => <div /> }
  ];
  return { list, columns };
};

const getMapReports = response => {
  let list = [];
  if (response) {
    list = response.filter(item => {
      return item.appType === 'map';
    });
  }
  const columns = [
    { name: 'Map name', selector: 'mapName', sortable: true },
    { name: 'Installed*', selector: 'noOfInstalledVehicleMap', sortable: true },
    { name: '% of Installation', selector: 'percentageOfInstallationMap', sortable: true },
    { name: 'Vehicles', selector: 'totalNoOfVehicleMap', sortable: true },
    { name: 'Export Failure', selector: 'mapName', sortable: true, cell: row => <div /> },
    { name: 'Export All', selector: 'mapName', sortable: true, cell: row => <div /> }
  ];
  return { list, columns };
};

const getReportsList = response => {
  return {
    applications: getApplicationReports(response),
    firmwares: getFirmWareReports(response),
    maps: getMapReports(response)
  };
};

const initialState = {
  reports: getApplicationReports(),
  showFilters: false,
  reportsList: {
    applications: getApplicationReports(),
    firmwares: getFirmWareReports(),
    maps: getMapReports()
  }
};

const businessReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BUSINESS_REPORTS_LOADED: {
      const reportsList = getReportsList(action.response);
      return { ...state, reportsList, reports: reportsList.applications };
    }
    case types.BUSINESS_REPORTS_FAILED:
      return state;
    case types.GET_BUSINESS_APPLICATON_REPORTS:
      return { ...state, reports: state.reportsList.applications };
    case types.GET_BUSINESS_FIRMWARE_REPORTS:
      return { ...state, reports: state.reportsList.firmwares };
    case types.GET_BUSINESS_MAP_REPORTS:
      return { ...state, reports: state.reportsList.maps };
    default:
      return state;
  }
};

export default businessReportReducer;
