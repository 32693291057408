const styles = {
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  loginContent: {
    display: 'block',
    textAlign: 'center',
    width: '100%',
    zIndex: 1000
  },
  error: {
    color: '#FF0000'
  },
  loginBg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0
  },
  title: {
    fontSize: '100px',
    color: '#FFFFFF',
    display: 'block',
    lineHeight: '100px'
  },
  subTitle: {
    fontSize: '30px',
    color: '#FFFFFF',
    display: 'block'
  },
  fieldContainer: {
    justifyContent: 'center',
    margin: '20px'
  },
  textField: {
    margin: '5px',
    borderBottom: '2px sold #FFFFFF',
    border: 'none',
    width: '350px'
  },
  input: {
    color: '#FFFFFF',
    padding: '10px 0px'
  },
  loginBtn: {
    width: '250px',
    fontSize: '20px',
    color: '#FFFFFF',
    margin: '10px',
    '&:disabled': {
      backgroundColor: '#b6b6b6'
    }
  }
};
export default styles;
