import axios from 'axios';
import { apiMiddleware } from '../utility/apiMiddleware';
import { appendTenant } from '../utility/appendTenant';
import { GET_EXPRESSIONS } from './apis';

const getExpressions = async version => {
  //const url = `${GET_EXPRESSIONS + version}?tenant=${localStorage.getItem('tenantHandle') || ''}`;
  try {
    //const { data } = await axios.get(url);
    //const response = await apiMiddleware(url, 'get');
    const response = await apiMiddleware(
      appendTenant(`${GET_EXPRESSIONS + version}`, ['tenant']),
      'get'
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export default getExpressions;
