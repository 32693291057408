import * as types from '../constants';

const initialState = {
  list: []
};

function installationStatusReportReducer(state = initialState, action) {
  switch (action.type) {
    case types.INSTALLATION_STATUS_REPORTS_LOADED:
      return { ...state, list: action.response };
    default:
      return state;
  }
}

export default installationStatusReportReducer;
