import axios from 'axios';
import { store } from '..';
import { getApiHeaderConfig } from '../common/components/ApiConfig';
import { apiMiddleware } from '../utility/apiMiddleware';
import { appendTenant } from '../utility/appendTenant';

import {
  GET_FIRMWARES,
  getSemType,
  GET_FIRMWARE_VERSION,
  GET_FIRMWARE,
  CREATE_FIRMWARE,
  DELETE_FIRMWARE,
  GET_FIRMWARE_ARTIFACTORY_URL,
  getTenantDetails,
  getSemTypeDetails,
  getSemDetails,
  MIGRATE_CLUP_TO_XML
} from './apis';

export const getFirmWares = async () => {
  const semType = getSemType();
  const vceSemType = getSemTypeDetails();
  //const tenant = getTenantDetails();
  const tenant = getTenantDetails();
  const firmwareType = localStorage.getItem('firmwareType');
  let url = '';
  let semForApi = semType;
  semForApi = tenant === 'vce' && semType === 'sem2' ? getSemTypeDetails() : semType;
  if (semType === 'sem2' || semType === 'sem2.5') {
    if (firmwareType == 'swc' && vceSemType !== 'SID2') {
      url =
        '/portalapi/v1/installation_package/swcFirmwareForHome/' + semForApi + '?tenant=' + tenant;
    } else {
      url = GET_FIRMWARES + semForApi + '?tenant=' + tenant;
    }
  } else {
    url = GET_FIRMWARES + semForApi + '?tenant=' + tenant;
  }
  try {
    const response = await apiMiddleware(url, 'get');
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
export const getFirmware = async name => {
  try {
    const response = await apiMiddleware(
      appendTenant(GET_FIRMWARE + name, ['tenant']),
      'get',
      {},
      { ...getApiHeaderConfig('userName') }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getFirmwareVersion = async name => {
  let url = '';
  const sem = getSemDetails();
  const semType = getSemTypeDetails();
  const swcUrl = '/portalapi/v1/installation_package/swcfirmware/:name/version';
  const firmwareType = localStorage.getItem('firmwareType');
  if (firmwareType == 'swc' && sem !== 'sem1' && sem !== 'TAD1' && semType !== 'SID2') {
    url = swcUrl.replace(':name', name);
  } else {
    url = GET_FIRMWARE_VERSION.replace(':name', name);
  }

  try {
    const response = await apiMiddleware(appendTenant(url, ['tenant']), 'get');
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const create = async item => {
  try {
    const response = await apiMiddleware(
      appendTenant(CREATE_FIRMWARE, ['tenant']),
      'post',
      item,
      getApiHeaderConfig('userName')
    );

    if (response.statusCode == 200) {
      return { flag: true, data: response.data };
    } else {
      return { flag: false, status: response.statusCode };
    }
  } catch (err) {
    return { flag: false, err };
  }
};

export const deleteFirmware = async name => {
  let url = DELETE_FIRMWARE.replace(':name', name);
  try {
    const response = await apiMiddleware(
      appendTenant(url, ['tenant']),
      'delete',
      {},
      { ...getApiHeaderConfig('userName&tenant') }
    );
    if (response.statusCode == 200) {
      return { flag: true, data: response.data };
    } else {
      return { flag: false, status: response.statusCode };
    }
  } catch (err) {
    return { flag: false, err };
  }
};

export const fetchArtifactoryData = async name => {
  let url = GET_FIRMWARE_ARTIFACTORY_URL;
  const header = getApiHeaderConfig('semClient&tenant');
  try {
    const response = await apiMiddleware(
      '/sems/getartifacts',
      'get',
      {},
      { ...getApiHeaderConfig('semClient&tenant') }
    );
    if (response.statusCode == 200) {
      return { flag: true, data: response.data };
    } else {
      return { flag: false, status: response.statusCode };
    }
  } catch (err) {
    return { flag: false, err };
  }
};

export const uploadArtifactoryData = async id => {
  let url = GET_FIRMWARE_ARTIFACTORY_URL;
  // let config = {};
  // config['headers'] = {
  //   clupFileType: clupFileType
  // };

  try {
    const response = await apiMiddleware(appendTenant(`sems/downloadartifact/${id}`), 'get', {});
    if (response.statusCode == 200) {
      return { flag: true, data: response.data };
    } else {
      return { flag: false, status: response.statusCode };
    }
  } catch (err) {
    return { flag: false, err };
  }
};

export const sendMigrateClupToXmlRequest = async (data, id, fileUploadType) => {
  let apiHeader = {};
  const semType = getSemTypeDetails();
  apiHeader['headers'] = {
    fileUploadType: semType === 'SID2' || semType === 'TAD1' ? fileUploadType : 'null'
  };

  try {
    const url = MIGRATE_CLUP_TO_XML.replace(':id', id);
    const response = await apiMiddleware(appendTenant(url, ['tenant']), 'post', data, {
      headers: { ...getApiHeaderConfig('userName&tenant').headers, ...apiHeader.headers }
    });
    return response;
  } catch (err) {
    return 'internal service error';
  }
};
