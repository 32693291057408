import * as types from '../constants';

function getFilteredApplication(ap) {
  if (ap.pendingVersion === 0) {
    return { ...ap, pendingVersion: '' };
  }
  return ap;
}

function getTechnicalReportApplications(response) {
  let list = [];
  if (response) {
    list = response.filter(function(item) {
      return item.appType && item.appType.toUpperCase() === 'APP';
    });
  }

  list = list.map(applicationObject => getFilteredApplication(applicationObject));

  const columns = [
    { name: 'App name' },
    { name: 'App version' },
    { name: 'Pending version' },
    { name: 'Status' },
    { name: 'Error Message' },
    { name: 'Time of last installation' }
  ];

  const fields = [
    { name: 'appName' },
    { name: 'appVersion' },
    { name: 'pendingVersion' },
    { name: 'status' },
    { name: 'errorMessageApp' },
    { name: 'lastInstallationDate' }
  ];
  return { list, columns, fields };
}

function getTechnicalReportMaps(response) {
  let list = [];
  if (response) {
    list = response.filter(item => {
      return item.appType && item.appType === 'map';
    });
  }
  const columns = [
    { name: 'Map name', selector: 'mapName' },
    { name: 'Map version', selector: 'mapVersion' },
    { name: 'Pending version', selector: 'pendingVersionMap' },
    { name: 'Status', selector: 'statusMap' },
    { name: 'Error Message', selector: 'errorMessageMap' },
    { name: 'Time of last installation', selector: 'lastInstallationDateMap' }
  ];

  const fields = [
    { name: 'mapName' },
    { name: 'mapVersion' },
    { name: 'pendingVersionMap' },
    { name: 'statusMap' },
    { name: 'errorMessageMap' },
    { name: 'lastInstallationDateMap' }
  ];
  return { list, columns, fields };
}

function getFilteredFirmware(fm) {
  if (fm.pendingVersionFW === 0) {
    return { ...fm, pendingVersionFW: '' };
  }
  return fm;
}

function getTechnicalReportFirmWares(response) {
  let list = [];
  if (response) {
    list = response.filter(item => {
      return item.appType && item.appType === 'firmware';
    });
    list = list.map(firmwareObject => getFilteredFirmware(firmwareObject));
  }
  const columns = [
    { name: 'Firmware name', selector: 'firmwareName' },
    { name: 'Firmware version', selector: 'firmwareVersion' },
    { name: 'Pending version', selector: 'pendingVersionFW' },
    { name: 'Status', selector: 'statusFW' },
    { name: 'Error Message', selector: 'errorMessageFW' },
    { name: 'Time of last installation', selector: 'lastInstallationDateFW' }
  ];

  const fields = [
    { name: 'firmwareName' },
    { name: 'firmwareVersion' },
    { name: 'pendingVersionFW' },
    { name: 'statusFW' },
    { name: 'errorMessageFW' },
    { name: 'lastInstallationDateFW' }
  ];
  return { list, columns, fields };
}

function getKolaVariants(response) {
  let list = [];
  if (response && response.length > 0) {
    list = response[0].kolaVariantsList;
  }
  const columns = [
    { name: 'Kola Family', selector: 'kolaVariant' },
    { name: 'Kola Variant', selector: 'kolaVariant' },
    { name: 'Description', selector: 'description' }
  ];
  const fields = [{ name: 'kolaFamily' }, { name: 'kolaVariant' }, { name: 'description' }];
  return { list, columns, fields };
}

function getAppStatusReport(response) {
  let list = [];
  if (response && response.length > 0) {
    list = response[0].appStatus;
  }
  return list || [];
}

function getReport(response) {
  const report = {
    vehicleDetail: null,
    kolaVariants: getKolaVariants(),
    applications: getTechnicalReportApplications(),
    maps: getTechnicalReportMaps(),
    firmwares: getTechnicalReportFirmWares(),
    appStatusReport: getAppStatusReport()
  };
  if (response && response.length > 0) {
    report.vehicleDetail = response[0];
    report.applications = getTechnicalReportApplications(response);
    report.maps = getTechnicalReportMaps(response);
    report.firmwares = getTechnicalReportFirmWares(response);
    report.kolaVariants = getKolaVariants(response);
    report.appStatusReport = getAppStatusReport(response);
  }
  return report;
}

const initialState = {
  isApiLoading: false,
  report: {
    vehicleDetail: null,
    kolaVariants: getKolaVariants(),
    applications: getTechnicalReportApplications(),
    maps: getTechnicalReportMaps(),
    firmwares: getTechnicalReportFirmWares(),
    appStatusReport: getAppStatusReport()
  }
};

const technicalReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TECHNICAL_REPORT_LOADED:
      return { ...state, isApiLoading: false, report: getReport(action.response.data) };
    case types.SHOW_TECHNICALREPORT_API_LOADING:
      return { ...state, isApiLoading: true };
    case types.TECHNICAL_REPORT_FAILED:
      return { ...initialState };
    case types.RESET_TECHNICAL_REPORT:
      return { ...initialState };
    case types.CLEAR_TECHNICAL_REPORT_DATA:
      return { ...initialState };

    default:
      return state;
  }
};

export default technicalReportReducer;
