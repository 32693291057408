import { put, call, delay } from 'redux-saga/effects';
import {
  getMetaDatas,
  getMatadataValues,
  getMatadataUninitializedValues,
  getAllMetaDataValues,
  getAllMetaDatas
} from '../services/metaDataService';
import {
  METADATAS_LOADED,
  METADATAS_FAILED,
  METADATA_VALUES_LOADED,
  METADATA_VALUES_FAILED,
  METADATA_UNINITIALIZED_VALUES_LOADED,
  METADATA_UNINITIALIZED_VALUES_FAILED,
  ALL_METADATAS_LOADED,
  ALL_METADATAS_FAILED,
  ALL_METADATA_VALUES_LOADED,
  ALL_METADATA_VALUES_FAILED,
  SHOW_LOADING,
  HIDE_LOADING,
  METADATAS_LOADING_START,
  METADATAS_LOADING_END,
  METADATA_VALUES_LOADING_END,
  METADATA_VALUES_LOADING_START,
  UPDATE_UNINITIALIZED_VALUES
} from '../constants';

export function* fetchMetaDatas() {
  try {
    yield put({ type: METADATAS_LOADING_START });
    const response = yield call(getMetaDatas);
    yield put({ type: METADATAS_LOADED, response });
    yield put({ type: METADATAS_LOADING_END });
  } catch (error) {
    yield put({ type: METADATAS_FAILED, error });
    yield put({ type: METADATAS_LOADING_END });
  }
}

export function* fetchMatadataValues(payload) {
  try {
    yield put({ type: METADATA_VALUES_LOADING_START });
    const response = yield call(getMatadataValues, payload.name);
    yield put({ type: METADATA_VALUES_LOADED, response });
    yield put({ type: METADATA_VALUES_LOADING_END });
  } catch (error) {
    yield put({ type: METADATA_VALUES_LOADING_END, error });
  }
}

export function* fetchMatadataUninitializedValues(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getMatadataUninitializedValues, payload.name);
    yield put({ type: METADATA_UNINITIALIZED_VALUES_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: METADATA_UNINITIALIZED_VALUES_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchAllMetaDatas(payload) {
  try {
    const response = yield call(getAllMetaDatas, payload.component);
    yield put({ type: ALL_METADATAS_LOADED, response });
  } catch (error) {
    yield put({ type: ALL_METADATAS_FAILED, error });
  }
}

export function* fetchAllMetaDataValues() {
  try {
    const response = yield call(getAllMetaDataValues);
    yield put({ type: ALL_METADATA_VALUES_LOADED, response });
  } catch (error) {
    yield put({ type: ALL_METADATA_VALUES_FAILED, error });
  }
}

export function* fetchAllUpdatedMatadata(payload) {
  try {
    yield put({ type: METADATA_VALUES_LOADING_START });
    const metadata = yield call(getMatadataValues, payload.data.name);
    yield put({ type: METADATA_VALUES_LOADED, response: metadata });
    // yield delay(10000);
    // const uninitializedValues = yield call(getMatadataUninitializedValues, payload.name);
    // yield put({ type: METADATA_UNINITIALIZED_VALUES_LOADED, response: uninitializedValues });
    yield put({ type: UPDATE_UNINITIALIZED_VALUES, response: payload.data.value });
    yield put({ type: METADATA_VALUES_LOADING_END });
  } catch (error) {
    yield put({ type: METADATA_UNINITIALIZED_VALUES_FAILED, error });
    yield put({ type: METADATA_VALUES_LOADING_END });
  }
}
