import axios from 'axios';
import {
  LOGIN_URL,
  VALIDATE_TOKEN,
  GET_CURRENT_TENANT,
  UPDATE_SEMS_CLIENT,
  GET_CLAIMS
} from './apis';
import { Base64 } from '../common/constants/loginBase64';
import { apiMiddleware } from '../utility/apiMiddleware';

export const login = request => {
  const authorization = `Basic ${Base64.encode(
    `${request.user.username}:${request.user.password}`
  )}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization
    }
  };

  //   return axios.post(LOGIN_URL, null, options).then(
  //     response => {
  //       if (response.status === 200) {
  //         const token = response.headers['x-auth-token'];
  //         if (token) {
  //           localStorage.setItem('token', token);
  //         }
  //         return { token };
  //       }

  //       return { token: 'LOGIN_FAILED' };
  //     },
  //     error => {
  //       return { token: 'LOGIN_FAILED' };
  //     }
  //   );
  // };
  // end of api call

  //core component api method
  return axios.get(LOGIN_URL, options).then(
    response => {
      if (response.status === 200) {
        let token = response.headers['x-auth-token'];
        if (!token) {
          token = response.data;
        }
        if (token) {
          localStorage.setItem('token', token);
        }
        return { token };
      }

      return { token: 'LOGIN_FAILED' };
    },
    error => {
      console.log('login error - ', error);
      return { token: 'LOGIN_FAILED' };
    }
  );
};

// export const validateToken = async () => {
//   try {
//     const { data } = await axios.post(VALIDATE_TOKEN);
//     return data;
//   } catch (err) {
//     console.log(err);
//   }
// };

//COre component api
export const validateToken = async () => {
  try {
    //const { data } = await axios.get(VALIDATE_TOKEN);
    const response = await apiMiddleware(VALIDATE_TOKEN, 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCurrentTenant = async details => {
  const url = `${GET_CURRENT_TENANT}?tenant=${details.tenant}`;

  try {
    let semVal = details.semVal;
    if (details.tenant === 'vtna' || details.tenant === 'mack') {
      semVal = 'sem2.5';
    } else if (details.tenant === 'vbc' && semVal === null) {
      semVal = 'sem2';
    } else if (semVal === null) {
      semVal = 'sem1';
    }
    let config = {};
    config['headers'] = { semClient: semVal };
    //const { data } = await axios.get(url);
    const response = await axios.get(url, config);
    //const response = await apiMiddleware(url, 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateSemsClient = async inputData => {
  try {
    //const { data } = await axios.post(UPDATE_SEMS_CLIENT, inputData);
    const response = await apiMiddleware(UPDATE_SEMS_CLIENT, 'post', inputData);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getClaims = async ({ userName, tenant }) => {
  const url =
    tenant == 'vce'
      ? `${`${GET_CLAIMS + 'role/tenant/' + tenant}/username/`}${userName}`
      : `${`${GET_CLAIMS + 'tenant/' + tenant}/username/`}${userName}`;

  try {
    //const { data } = await axios.get(url);
    const response = await apiMiddleware(url, 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
