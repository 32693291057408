import * as types from '../constants';

const initialState = {
  dataThresholdReport: [],
  dataThresholdReportApiFailed: false,
  dataThresholdReportApiCallInProgress: false,
  isApiLoading: false
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getConstructedData = apiData => {
  return apiData.map(dataObj => {
    // const updatedObj = {...dataObj,{}}
    const thresholdType = dataObj.thresholdType.split('_');
    const timeStamp = dataObj.requestTime.split('T');
    return {
      ...dataObj,
      requestTime: timeStamp[0] + '|' + timeStamp[1].split('+')[0],
      thresholdType:
        capitalizeFirstLetter(thresholdType[0]) + ' ' + capitalizeFirstLetter(thresholdType[1])
    };
  });
};
const dataThresholdReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DATATHRESHOLD_REPORTS_LOADED:
      const contructedData = getConstructedData(action.response);
      return {
        ...state,
        dataThresholdReport: contructedData,
        isApiLoading: false
      };
    case types.DATATHRESHOLD_REPORT_API_LOADING:
      return { ...state, isApiLoading: true };
    case types.DATATHRESHOLD_REPORTS_FAILED:
      return { ...initialState };
    default:
      return state;
  }
};

export default dataThresholdReducer;
