import * as types from '../constants';

const initialState = {
  auditLogs: []
};

function auditLogReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUDIT_LOG_REPORTS_LOADED:
      return { ...state, auditLogs: action.response };
    case types.CLEAR_AUDIT_LOG_REPORTS:
      return initialState;
    default:
      return state;
  }
}

export default auditLogReducer;
