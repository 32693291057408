import {
  GET_APPLICATIONS,
  getSemType,
  GET_APPLICATION,
  GET_APPLICATION_VERSION,
  GET_AWS_S3_DETAILS,
  DELETE_APPLICATION,
  getSemTypeDetails,
  // SEND_EXTERNAL_REFERENCE_REQUEST,
  GET_APPLICATIONS_MULTIAPK,
  getTenantDetails
} from './apis';
import { getApiHeaderConfig } from '../common/components/ApiConfig';
import { apiMiddleware } from '../utility/apiMiddleware';
import { appendTenant } from '../utility/appendTenant';
import { store } from '..';

export const getApplications = async appListType => {
  const semType = getSemType();
  const tenant = getTenantDetails();
  let semForApi = semType;
  semForApi =
    tenant === 'vce' && semType === 'sem2'
      ? getSemTypeDetails()
      : tenant === 'vbc'
      ? 'sem2'
      : semType;
  let url =
    semType === 'sem2.5'
      ? GET_APPLICATIONS_MULTIAPK
      : `${GET_APPLICATIONS + semForApi}?tenant=${tenant}`;
  //let url = GET_APPLICATIONS + semForApi;
  try {
    //const { data, status } = await axios.get(url);
    const response = await apiMiddleware(
      url,
      'get',
      {},
      { ...getApiHeaderConfig('semClient&tenant') }
    );
    if (response.statusCode == 200) {
      const modifiedData = response.data.map(data => {
        return {
          ...data,
          contextName: data.contextName || '',
          type: data.rro ? 'RRO' : data.dependency === 'Y' ? 'BASE' : 'NA'
        };
      });

      return modifiedData;
    } else {
      return [];
    }
  } catch (error) {
    return { status: 1004 };
  }
};

// application api call for core comonent integration
// export const getApplicationsFromCoreComponent = async () => {
//   const semType = getSemType();
//   const tenant =  getTenantDetails();
//   let semForApi = semType;
//   semForApi = (tenant === 'vce' && semType === 'sem2') ?'DCM1':semType;
//   const url = '/portalapi/v1/installation_package/apps/' + semForApi+'?tenant='+tenant;
//   console.log('calling core component & end point without domain ', url );
//   try {
//     const { data } = await axios.get(url);
//     console.log("data fetched from core component ",data);
//   } catch (error) {
//     console.log("Error from core component ",error);
//     return { status: 1004 };
//   }
// };

export const getApplication = async name => {
  try {
    //const { data } = await axios.get(GET_APPLICATION + name + '?tenant=' + getTenantDetails());
    const { data } = await apiMiddleware(appendTenant(GET_APPLICATION + name, ['tenant']), 'get');
    return data;
  } catch (err) {
    console.log(err);
    return { status: 1004 };
  }
};

export const getApplicationVersion = async name => {
  let url = GET_APPLICATION_VERSION.replace(':name', name);
  //url = url + `?tenant=${localStorage.getItem('tenantHandle') || ''}`;
  try {
    //const { data } = await axios.get(url, getApiHeaderConfig('userName'));
    const { data } = await apiMiddleware(
      appendTenant(url, ['tenant']),
      'get',
      {},
      { ...getApiHeaderConfig('userName') }
    );
    return data;
  } catch (err) {
    console.log(err);
    return { status: 1004 };
  }
};

export const getAWSS3Configuration = async () => {
  try {
    //const { data } = await axios.get(GET_AWS_S3_DETAILS);
    const { data } = await apiMiddleware(GET_AWS_S3_DETAILS, 'get');
    console.log('GET_AWS_S3_DETAILS', data);
    return data;
  } catch (err) {
    console.log(err);
    return { status: 1004 };
  }
};

export const deleteApplication = async name => {
  try {
    // const { data, status } = await axios.delete(DELETE_APPLICATION.replace(':name', name), {
    //   ...getApiHeaderConfig('userName&tenant')
    // });
    let url = DELETE_APPLICATION.replace(':name', name);
    const response = await apiMiddleware(
      appendTenant(url, ['tenant']),
      'delete',
      {},
      { ...getApiHeaderConfig('userName&tenant') }
    );

    if (response.errorFlag) {
      return response;
    }
    if (response.statusCode == 200 && response.data.deletedStatus === 'FALSE') {
      response.errorFlag = true;
      if (response.data.msg) {
        response.errorMessage = response.data.msg;
      } else {
        response.errorMessage = null;
      }

      return response;
    }

    return response;
  } catch (err) {
    return { flag: false, err };
  }
};

// export const sendExternalReferenceRequest = async (data, id) => {
//   try {
//     const url = SEND_EXTERNAL_REFERENCE_REQUEST.replace(':id', id);
//     const response = await apiMiddleware(appendTenant(url, ['tenant']), 'post', data, {
//       ...getApiHeaderConfig('userName&tenant')
//     });
//     return response;
//   } catch (err) {
//     //console.log(err);
//     return 'internal service error';
//   }
// };
