import { put, call } from 'redux-saga/effects';
import { getFirmWares, getFirmware, getFirmwareVersion, create } from '../services/firmWareService';
import {
  FIRMWARES_LOADED,
  FIRMWARES_FAILED,
  HIDE_LOADING,
  SHOW_LOADING,
  FIRMWARE_LOADED,
  FIRMWARE_VERSION_LOADED,
  FIRMWARE_VERSION_FAILED,
  FIRMWARE_FAILED,
  CREATE_FIRMWARE_FAILED,
  FIRMWARES_LOADING_START,
  FIRMWARES_LOADING_END,
  FIRMWARE_VERSION_LOADING_START,
  FIRMWARE_VERSION_LOADING_END
} from '../constants';

export function* fetchFirmWares() {
  try {
    yield put({ type: FIRMWARES_LOADING_START });
    const response = yield call(getFirmWares);
    //console.log(response);
    yield put({ type: FIRMWARES_LOADED, response });
    yield put({ type: FIRMWARES_LOADING_END });
  } catch (error) {
    //console.log(error)
    yield put({ type: FIRMWARES_FAILED, error });
    yield put({ type: FIRMWARES_LOADING_END });
  }
}

export function* fetchFirmware(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getFirmware, payload.name);
    yield put({ type: FIRMWARE_LOADED, response });
  } catch (error) {
    yield put({ type: FIRMWARE_FAILED, error });
  }
}

export function* fetchFirmwareVersion(payload) {
  try {
    yield put({ type: FIRMWARE_VERSION_LOADING_START });
    const response = yield call(getFirmwareVersion, payload.name);
    yield put({ type: FIRMWARE_VERSION_LOADED, response });
    yield put({ type: FIRMWARE_VERSION_LOADING_END });
  } catch (error) {
    yield put({ type: FIRMWARE_VERSION_FAILED, error });
    yield put({ type: FIRMWARE_VERSION_LOADING_END });
  }
}

export function* createFirmware(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const createResponse = yield call(create, payload.item);
    const response = yield call(getFirmWares);
    yield put({ type: FIRMWARES_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: CREATE_FIRMWARE_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}
