import axios from 'axios';
import { apiMiddleware } from '../utility/apiMiddleware';
import { GET_VEHICLE_STATUS_REPORT } from './apis';

export default async function getVehicleStatusReports() {
  try {
    //const { data } = await axios.get(GET_VEHICLE_STATUS_REPORT);
    const response = await apiMiddleware(GET_VEHICLE_STATUS_REPORT, 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
