import * as types from '../constants';

const initialState = {
  data: [],
  loaded: false
};

function vehicleStatusReportReducer(state = initialState, { type, response }) {
  switch (type) {
    case types.FETCH_VEHICLE_STATUS_REPORTS_LOADED:
      return { ...state, data: response };
    case types.SHOW_LOADING:
      return { ...state, loaded: true };
    case types.HIDE_LOADING:
      return { ...state, loaded: false };
    default:
      return state;
  }
}

export default vehicleStatusReportReducer;
