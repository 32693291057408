import axios from 'axios';
import { getApiHeaderConfig } from '../common/components/ApiConfig';
import { apiErrorHandler } from './apiErrorHandler';

export const apiMiddleware = async (url, methodType, data = {}, config = {}) => {
  let apiResponse = null;
  const semClientConfig = getApiHeaderConfig('semClient');
  config['headers'] = { ...config['headers'], ...semClientConfig['headers'] };
  switch (methodType) {
    case 'get':
      apiResponse = await axios.get(url, config);
      break;
    case 'post':
      apiResponse = await axios.post(url, data, config);
      break;
    case 'delete':
      apiResponse = await axios.delete(url, config);
      break;
    case 'put':
      apiResponse = await axios.put(url, data, config);      
      break;
    default:
      apiResponse = { data: null, status: 404 };
      
  }

  return apiErrorHandler(apiResponse);
};
