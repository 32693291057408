import axios from 'axios';

import {
  GET_SETS,
  GET_TEST_SETS,
  getSemType,
  getTenantDetails,
  GET_SET,
  CREATE_SET,
  GET_TEST_SET,
  getSemTypeDetails
} from './apis';

import { getApiHeaderConfig } from '../common/components/ApiConfig';
import { apiMiddleware } from '../utility/apiMiddleware';
import { appendTenant } from '../utility/appendTenant';

export const getSets = async () => {
  const semType = getSemType();
  const tenant = getTenantDetails();
  let semForApi = semType;
  semForApi = tenant === 'vce' && semType === 'sem2' ? getSemTypeDetails() : semType;
  try {
    // const { data, status } = await axios.get(
    //   GET_SETS + semForApi + '?tenant=' + tenant
    // );
    const response = await apiMiddleware(GET_SETS + semForApi + '?tenant=' + tenant, 'get');
    if (response.statusCode === 200) {
      return response.data;
    } else if (response.statusCode === 404) {
      return [];
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

export const getTestSets = async () => {
  const semType = getSemType();
  const tenant = getTenantDetails();
  let semForApi = semType;
  semForApi = tenant === 'vce' && semType === 'sem2' ? getSemTypeDetails() : semType;
  try {
    //const { data, status } = await axios.get(GET_TEST_SETS + semForApi + '?tenant=' + tenant);
    const response = await apiMiddleware(GET_TEST_SETS + semForApi + '?tenant=' + tenant, 'get');
    if (response.statusCode === 200) {
      return response.data;
    } else if (response.statusCode === 404) {
      return [];
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};
// tenant info not required for qa env, only iot
export const getSet = async name => {
  let url = GET_SET + name; // + `?tenant=${localStorage.getItem('tenantHandle') || ''}`;
  try {
    //const { data, status } = await axios.get(url, getApiHeaderConfig('userName'));
    const response = await apiMiddleware(
      appendTenant(url, ['tenant']),
      'get',
      {},
      getApiHeaderConfig('userName')
    );
    if (response.statusCode === 200) {
      return response.data;
    } else if (response.statusCode === 404) {
      return [];
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};
// tenant info not required for qa env, only iot
export const getTestSet = async name => {
  let url = GET_TEST_SET + name; // + `?tenant=${localStorage.getItem('tenantHandle') || ''}`;
  try {
    //const { data, status } = await axios.get(url, getApiHeaderConfig('userName'));
    const response = await apiMiddleware(
      appendTenant(url, ['tenant']),
      'get',
      {},
      getApiHeaderConfig('userName')
    );
    if (response.statusCode === 200) {
      return response.data;
    } else if (response.statusCode === 404) {
      return [];
    }
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
// tenant info not required for qa env, only iot
export const create = async set => {
  const tenant = getTenantDetails();
  let url = CREATE_SET + `?tenant=${tenant || ''}`;
  try {
    // const { data, status } = await axios.post(
    //   url + `?tenant=${localStorage.getItem('tenantHandle') || ''}`,
    //   set,
    //   getApiHeaderConfig('userName')
    // );

    const response = await apiMiddleware(
      url + `?tenant=${tenant || ''}`,
      'post',
      set,
      getApiHeaderConfig('userName')
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
