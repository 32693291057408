import * as types from '../constants';
import { sortVersions, sort } from '../utils';

const initialState = {
  firmWares: [],
  firmware: undefined,
  firmwareVersion: [],
  loading: true,
  versionLoading: false
};

function firmWareReducer(state = initialState, action) {
  switch (action.type) {
    case types.FIRMWARES_LOADED:
      sort(action.response, 'name');
      return { ...state, firmWares: action.response };
    case types.FIRMWARE_LOADED:
      return { ...state, firmware: action.response };
    case types.FIRMWARE_VERSION_LOADED:
      sortVersions(action.response);
      if (action.response) {
        action.response.forEach(item => {
          item.rowId = `row_id_${new Date().getTime()}${item.id}`;
        });
      }
      return { ...state, firmwareVersion: action.response };
    case types.FETCH_FIRMWARE:
      return { ...state, firmwareVersion: [] };
    case types.FIRMWARE_VERSION_FAILED:
      return { ...state, firmwareVersion: [] };
    case types.FIRMWARES_LOADING_START:
      return { ...state, loading: true };
    case types.FIRMWARES_LOADING_END:
      return { ...state, loading: false };

    case types.FIRMWARE_VERSION_LOADING_START:
      return { ...state, versionLoading: true };
    case types.FIRMWARE_VERSION_LOADING_END:
      return { ...state, versionLoading: false };
    default:
      return state;
  }
}

export default firmWareReducer;
