const primary = '#4FB7F9';
const secondary = '#FF0000';
const warning = '#FF0000';
const success = '#3CD4A0';
const info = '#9013FE';
const sem1 = '#183890';
const sem2 = '#692f47';

export default {
  contentGrid: {
    background: '#F9FAFB',
    height: 'calc(100vh - 64px)',
    display: 'flex'
  },
  palette: {
    // type: 'dark',
    primary: {
      light: '#779eff',
      main: '#3870e4',
      dark: '#0046b1',
      shade: 'rgba(56, 112, 228, 0.2)',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#fff559',
      main: '#FF0000',
      dark: '#db4e4e',
      shade: '#fff559',
      contrastText: '#262626'
    },
    error: {
      light: '#ff6f60',
      main: '#e53935',
      dark: '#ab000d',
      shade: '#f7605d',
      contrastText: '#ffffff'
    },
    action: {
      success: '#069c13',
      warning: '#f79626',
      error: '#d3322d'
    },
    warning: {
      main: warning
    },
    success: {
      main: success
    },
    info: {
      main: info
    },
    sem1: {
      main: sem1
    },
    sem2: {
      main: sem2
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#6E6E6E',
      hint: '#939393'
    },
    background: {
      default: '#FFFFFF',
      light: '#F3F5FF'
    }
  },
  customShadows: {
    widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          // minHeight: '56px'
        }
      }
    },
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       height: '56px'
    //     }
    //   }
    // },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: '#00000030'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(224, 224, 224, .5)'
        },
        head: {
          fontSize: '13px' //'0.95rem'
        },
        body: {
          fontSize: '13px' //'0.95rem'
        }
      }
    }
  },

  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: '#4A4A4A1A'
      }
    },
    MuiMenu: {
      paper: {
        boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
      }
    },
    MuiSelect: {
      icon: {
        color: '#B9B9B9'
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#F3F5FF !important',
          '&:focus': {
            backgroundColor: '#F3F5FF'
          }
        }
      },
      button: {
        '&:hover, &:focus': {
          backgroundColor: '#F3F5FF'
        }
      }
    },
    MuiFab: {
      root: {
        '&$disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'auto'
        }
      }
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white'
      }
    },
    MuiTableRow: {
      root: {
        height: '56px'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)'
      },
      head: {
        fontSize: '0.95rem'
      },
      body: {
        fontSize: '0.95rem'
      }
    }
  }
};
