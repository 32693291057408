import { takeLatest } from 'redux-saga/effects';
import { login, updateSemsClient, refreshCredentials } from './loginSaga';
import {
  fetchApplications,
  fetchApplication,
  fetchApplicationVersion,
  fetchAWSS3Configuration,
  deleteApplication
} from './applicationSaga';
import {
  fetchMaps,
  fetchMap,
  fetchMapVersion,
  createMap,
  deleteMap,
  fetchMapNamesForUpload
} from './mapSaga';
import {
  fetchFirmWares,
  fetchFirmware,
  fetchFirmwareVersion,
  createFirmware
} from './firmWareSaga';
import { fetchSets, fetchTestSets, fetchSet, fetchTestSet } from './setSaga';
import {
  fetchBusinessReports,
  fetchTechnicalReport,
  fetchDeniedAccessReports,
  fetchInstallationStatusReports,
  fetchAuditLogReports,
  fetchOtaLockReports,
  fetchAnalysisToolReport,
  fetchDataThresholdReport,
  fetchVehicleSoftwareDetails,
  fetchSoftwareUpdateCriteria
} from './reportSaga';
import {
  fetchMetaDatas,
  fetchMatadataValues,
  fetchMatadataUninitializedValues,
  fetchAllMetaDatas,
  fetchAllMetaDataValues,
  fetchAllUpdatedMatadata
} from './metaDataSaga';
import { fetchUsers, fetchUserRoles } from './userSaga';

import { fetchExpressions } from './expressionSaga';

import fetchDownloadMaps from './downloadMapSaga';
import vehicleStatusReport from './vechicleStatusSaga';

import * as types from '../constants';

export function* watchLogin() {
  yield takeLatest(types.LOGIN, login);
}

export function* watchUpdateSemsClient() {
  yield takeLatest(types.UPDATE_SEMS_CLIENT, updateSemsClient);
}
export function* watchRefreshCredentials() {
  yield takeLatest(types.REFRESH_CREDENTIALS, refreshCredentials);
}

export function* watchApplications() {
  yield takeLatest(types.FETCH_APPLICATIONS, fetchApplications);
}

export function* watchDeleteApplication() {
  yield takeLatest(types.DELETE_APPLICATION, deleteApplication);
}

export function* watchApplication() {
  yield takeLatest(types.FETCH_APPLICATION, fetchApplication);
}

export function* watchApplicationVersion() {
  yield takeLatest(types.FETCH_APPLICATION_VERSION, fetchApplicationVersion);
}

export function* watchMaps() {
  yield takeLatest(types.FETCH_MAPS, fetchMaps);
}

export function* watchMapNamesForUpload() {
  yield takeLatest(types.FETCH_MAPNAMES_FOR_UPLOAD, fetchMapNamesForUpload);
}

export function* watchMap() {
  yield takeLatest(types.FETCH_MAP, fetchMap);
}

export function* watchMapVersion() {
  yield takeLatest(types.FETCH_MAP_VERSION, fetchMapVersion);
}

export function* watchCreateMap() {
  yield takeLatest(types.CREATE_MAP, createMap);
}

export function* watchDeleteMap() {
  yield takeLatest(types.DELETE_MAP, deleteMap);
}

export function* watchFirmWares() {
  yield takeLatest(types.FETCH_FIRMWARES, fetchFirmWares);
}

export function* watchFirmware() {
  yield takeLatest(types.FETCH_FIRMWARE, fetchFirmware);
}

export function* watchCreateFirmware() {
  yield takeLatest(types.CREATE_FIRMWARE, createFirmware);
}

export function* watchFirmwareVersion() {
  yield takeLatest(types.FETCH_FIRMWARE_VERSION, fetchFirmwareVersion);
}

export function* watchSets() {
  yield takeLatest(types.FETCH_SETS, fetchSets);
}

export function* watchSet() {
  yield takeLatest(types.GET_SET, fetchSet);
}

export function* watchTestSet() {
  yield takeLatest(types.GET_TEST_SET, fetchTestSet);
}

export function* watchTestSets() {
  yield takeLatest(types.FETCH_TEST_SETS, fetchTestSets);
}

export function* watchBusinessReports() {
  yield takeLatest(types.FETCH_BUSINESS_REPORTS, fetchBusinessReports);
}

export function* watchTechnicalReport() {
  yield takeLatest(types.FETCH_TECHNICAL_REPORT, fetchTechnicalReport);
}

export function* watchAnaysisToolReport() {
  yield takeLatest(types.FETCH_ANALYSIS_TOOL_REPORT, fetchAnalysisToolReport);
}

export function* watchDataThresholdReport() {
  yield takeLatest(types.FETCH_DATA_THRESHOLD_REPORTS, fetchDataThresholdReport);
}

export function* watchVehicleSoftwareDetails() {
  yield takeLatest(types.FETCH_VEHICLE_SOFTWARE_DETAILS, fetchVehicleSoftwareDetails);
}

export function* watchAuditLogReports() {
  yield takeLatest(types.FETCH_AUDIT_LOG_REPORTS, fetchAuditLogReports);
}

export function* watchOtaLockReports() {
  yield takeLatest(types.FETCH_OTA_LOCK_REPORTS, fetchOtaLockReports);
}

export function* watchMetaDatas() {
  yield takeLatest(types.FETCH_METADATAS, fetchMetaDatas);
}

export function* watchMetadataValues() {
  yield takeLatest(types.FETCH_METADATA_VALUES, fetchMatadataValues);
}

export function* watchMetadataUninitializedValues() {
  yield takeLatest(types.FETCH_METADATA_UNINITIALIZED_VALUES, fetchMatadataUninitializedValues);
}

export function* watchAllUpdatedMetadata() {
  yield takeLatest(types.FETCH_ALL_UPDATED_METADATA, fetchAllUpdatedMatadata);
}

export function* watchDeniedAccessReports() {
  yield takeLatest(types.FETCH_DENIED_ACCESS_REPORTS, fetchDeniedAccessReports);
}

export function* watchInstallationStatusReports() {
  yield takeLatest(types.FETCH_INSTALLATION_STATUS_REPORTS, fetchInstallationStatusReports);
}

export function* watchUsers() {
  yield takeLatest(types.FETCH_USERS, fetchUsers);
}

export function* watchUserRoles() {
  yield takeLatest(types.FETCH_USER_ROLES, fetchUserRoles);
}

export function* watchAllMetaDatas() {
  yield takeLatest(types.FETCH_ALL_METADATAS, fetchAllMetaDatas);
}

export function* watchAllMetadataValues() {
  yield takeLatest(types.FETCH_ALL_METADATA_VALUES, fetchAllMetaDataValues);
}

export function* watchExpressions() {
  yield takeLatest(types.FETCH_EXPRESSIONS, fetchExpressions);
}

export function* watchDownloadMaps() {
  yield takeLatest(types.DOWNLOAD_MAPS, fetchDownloadMaps);
}

export function* watchVehicleStatusReport() {
  yield takeLatest(types.FETCH_VEHICLE_STATUS_REPORTS, vehicleStatusReport);
}

export function* watchAWSS3Config() {
  yield takeLatest(types.AWS_S3_SEM2_CONFIG, fetchAWSS3Configuration);
}

export function* watchSoftwareUpdateCriteria() {
  yield takeLatest(types.FETCH_SOFTWARE_UPDATE_CRITERIA, fetchSoftwareUpdateCriteria);
}
