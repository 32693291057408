import { store } from '..';
import { apiMiddleware } from '../utility/apiMiddleware';
import { appendTenant } from '../utility/appendTenant';
import {
  GET_BUSINESS_REPORTS,
  GET_TECHNICAL_REPORT,
  GET_DENIED_ACCESS_REPORTS,
  GET_INSTALLATION_STATUS_REPORTS,
  GET_AUDIT_LOG_REPORTS,
  GET_OTA_LOCK_REPORTS,
  GET_OTA_LOCK_REPORTS_BY_CHASSISID,
  GET_AUDIT_LOG_REPORTS_USER,
  GET_ANALYSIS_TOOL_REPORTS,
  GET_VEHICLE_SOFTWARE_DETAILS,
  GET_DATA_THRESHOLD_REPORTS,
  GET_SOFTWARE_UPDATE_CRITERIA,
  GET_VEHICLE_SOFTWARE_DETAILS_JSON,
  getTenantDetails
} from './apis';

export const getBusinessReports = async () => {
  //const tenant = getTenantDetails();
  try {
    //const { data } = await axios.get(`${GET_BUSINESS_REPORTS}?tenant=${tenant}`);
    const response = await apiMiddleware(appendTenant(GET_BUSINESS_REPORTS, ['tenant']), 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getTechnicalReport = async (id, imienumber) => {
  const tenant = getTenantDetails();
  try {
    // return await axios.get(
    //   `${GET_TECHNICAL_REPORT + id}?imeiNumber=${imienumber || ''}&tenant=${tenant}`
    // );
    return await apiMiddleware(
      `${GET_TECHNICAL_REPORT + id}?imeiNumber=${imienumber || ''}&tenant=${tenant}`,
      'get'
    );
  } catch (err) {
    console.log(err);
  }
};
// sample method for core component integration

export const getTechnicalReportForCoreComponent = async (id, imienumber) => {
  // console.log("calling technical report core comp api ");
  //   try {
  //    const  result  = await axios.get(`${'/portalapi/v1/technicalreport/'+id}?imeiNumber=${imienumber}`);
  //     console.log("data fetetched from core component api ", result);
  //   } catch (err) {
  //     console.log(err);
  //   }
};

export const getDeniedAccessReports = async id => {
  try {
    let url = GET_DENIED_ACCESS_REPORTS + '/' + getTenantDetails(); //localStorage.getItem('tenantHandle');
    if (id !== undefined && id.length > 0) {
      url = `${GET_DENIED_ACCESS_REPORTS}/find?chassisId=${id}`;
    }
    //const { data, status } = await axios.get(url);
    const response = await apiMiddleware(url, 'get');
    if (response.statusCode == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
  }
};

export const getInstallationStatusReports = async () => {
  //const tenant = getTenantDetails();
  try {
    //const { data } = await axios.get(`${GET_INSTALLATION_STATUS_REPORTS}?tenant=${tenant}`);
    const response = await apiMiddleware(
      //`${GET_INSTALLATION_STATUS_REPORTS}?tenant=${tenant}`,
      appendTenant(GET_INSTALLATION_STATUS_REPORTS, ['tenant']),
      'get'
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAuditLogReports = async request => {
  try {
    let data = [];
    if (request.entityType === 'USER') {
      // const result = await axios.get(
      //   `${GET_AUDIT_LOG_REPORTS_USER}?entityName=${request.entityName}&entityType=${
      //     request.entityType
      //   }&tenant=${localStorage.getItem('tenantHandle')}`
      // );
      const response = await apiMiddleware(
        `${GET_AUDIT_LOG_REPORTS_USER}?entityName=${request.entityName}&entityType=${
          request.entityType
        }&tenant=${getTenantDetails()}`,
        'get'
      );
      data = response.data;
    } else {
      // const result = await axios.get(
      //   `${GET_AUDIT_LOG_REPORTS}?entityName=${request.entityName}&entityType=${
      //     request.entityType
      //   }&tenant=${localStorage.getItem('tenantHandle')}`
      // );
      const response = await apiMiddleware(
        `${GET_AUDIT_LOG_REPORTS}?entityName=${request.entityName}&entityType=${
          request.entityType
        }&tenant=${getTenantDetails()}`,
        'get'
      );
      data = response.data;
    }
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getOtaLockReports = async chassisId => {
  try {
    //let url = GET_OTA_LOCK_REPORTS + localStorage.getItem('tenantHandle');
    let url = GET_OTA_LOCK_REPORTS + getTenantDetails();
    if (chassisId !== undefined && chassisId.length > 0) {
      url = GET_OTA_LOCK_REPORTS_BY_CHASSISID + chassisId;
    }
    //const { data } = await axios.get(url);
    const response = await apiMiddleware(url, 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAnalysisToolReports = async chassisIds => {
  const tenant = getTenantDetails();
  try {
    let url = `${GET_ANALYSIS_TOOL_REPORTS}?tenant=${tenant}`;
    //const { data } = await axios.post(url, chassisIds);
    const response = await apiMiddleware(url, 'post', chassisIds);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getDataThresholdReports = async chassisIds => {
  try {
    const tenant = getTenantDetails();
    let url = GET_DATA_THRESHOLD_REPORTS + `${tenant || ''}`;
    //const { data } = await axios.get(url);
    const response = await apiMiddleware(url, 'get');
    // const response = await apiMiddleware(
    //   appendTenant(GET_DATA_THRESHOLD_REPORTS, ['tenant']),
    //   'get'
    // );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getVehicleSoftwareDetails = async data => {
  try {
    const baseURL = GET_VEHICLE_SOFTWARE_DETAILS;
    // data.inputType === 'json' ? GET_VEHICLE_SOFTWARE_DETAILS_JSON : GET_VEHICLE_SOFTWARE_DETAILS;
    const connectionTypes =
      data.connectionType === 'ota'
        ? 'PRODUCTIVITY,INFOTAINMENT'
        : data.connectionType === 'usbUpdate'
        ? 'WORKSHOP'
        : 'WORKSHOP,PRODUCTIVITY,INFOTAINMENT';
    let url =
      baseURL +
      data.primaryId +
      '?connectionTypes=' +
      connectionTypes +
      '&tenant=' +
      getTenantDetails();
    //const { data } = await axios.get(url);
    const response = await apiMiddleware(url, 'get');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getSoftwareUpdateCriteria = async () => {
  try {
    const url = GET_SOFTWARE_UPDATE_CRITERIA;
    const response = await apiMiddleware(url, 'get');
    if (response.statusCode === 200) {
      return response.data;
    } else if (response.statusCode === 404) {
      return [];
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};
