import { put, call } from 'redux-saga/effects';
import {
  getBusinessReports,
  getTechnicalReport,
  getTechnicalReportForCoreComponent,
  getDeniedAccessReports,
  getInstallationStatusReports,
  getAuditLogReports,
  getOtaLockReports,
  getAnalysisToolReports,
  getVehicleSoftwareDetails,
  getDataThresholdReports,
  getSoftwareUpdateCriteria
} from '../services/reportsService';
import {
  BUSINESS_REPORTS_LOADED,
  BUSINESS_REPORTS_FAILED,
  TECHNICAL_REPORT_LOADED,
  HIDE_TECHNICALREPORT_API_LOADING,
  SHOW_TECHNICALREPORT_API_LOADING,
  TECHNICAL_REPORT_FAILED,
  DENIED_ACCESS_REPORTS_LOADED,
  DENIED_ACCESS_REPORTS_FAILED,
  HIDE_LOADING,
  SHOW_LOADING,
  INSTALLATION_STATUS_REPORTS_LOADED,
  INSTALLATION_STATUS_REPORTS_FAILED,
  AUDIT_LOG_REPORTS_LOADED,
  AUDIT_LOG_REPORTS_FAILED,
  OTA_LOCK_REPORTS_FAILED,
  OTA_LOCK_REPORTS_LOADED,
  DENIED_ACCESS_REPORTS_LOADING_START,
  DENIED_ACCESS_REPORTS_LOADING_END,
  ANALYSIS_TOOL_REPORTS_LOADED,
  ANALYSIS_TOOL_REPORTS_FAILED,
  ANALYSIS_REPORT_API_LOADING,
  DATATHRESHOLD_REPORT_API_LOADING,
  DATATHRESHOLD_REPORTS_LOADED,
  DATATHRESHOLD_REPORTS_FAILED,
  VEHICLE_SOFTWARE_DETAILS_LOADED,
  VEHICLE_SOFTWARE_DETAILS_FAILED,
  SOFTWARE_UPDATE_CRITERIA_LOADING_START,
  SOFTWARE_UPDATE_CRITERIA_LOADED,
  SOFTWARE_UPDATE_CRITERIA_LOADING_FAILED
} from '../constants';

export function* fetchBusinessReports() {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getBusinessReports);
    yield put({ type: BUSINESS_REPORTS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: BUSINESS_REPORTS_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchTechnicalReport(data) {
  try {
    // yield put({ type: SHOW_TECHNICALREPORT_API_LOADING });
    getTechnicalReportForCoreComponent(data.id);
    const response = yield call(getTechnicalReport, data.id);
    yield put({ type: TECHNICAL_REPORT_LOADED, response });
    // yield put({ type: HIDE_TECHNICALREPORT_API_LOADING });
  } catch (error) {
    yield put({ type: TECHNICAL_REPORT_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchDeniedAccessReports(data) {
  try {
    let id;
    if (data) {
      id = data.id;
    }
    yield put({ type: DENIED_ACCESS_REPORTS_LOADING_START });
    const response = yield call(getDeniedAccessReports, id);
    yield put({ type: DENIED_ACCESS_REPORTS_LOADED, response });
    yield put({ type: DENIED_ACCESS_REPORTS_LOADING_END });
  } catch (error) {
    yield put({ type: DENIED_ACCESS_REPORTS_FAILED, error });
    yield put({ type: DENIED_ACCESS_REPORTS_LOADING_END });
  }
}

export function* fetchInstallationStatusReports() {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getInstallationStatusReports);
    yield put({ type: INSTALLATION_STATUS_REPORTS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: INSTALLATION_STATUS_REPORTS_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchAuditLogReports(data) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getAuditLogReports, data.data);
    yield put({ type: AUDIT_LOG_REPORTS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: AUDIT_LOG_REPORTS_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchOtaLockReports(data) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getOtaLockReports, data.id);
    yield put({ type: OTA_LOCK_REPORTS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: OTA_LOCK_REPORTS_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchAnalysisToolReport(data) {
  try {
    yield put({ type: ANALYSIS_REPORT_API_LOADING });
    const response = yield call(getAnalysisToolReports, data.ids);
    yield put({ type: ANALYSIS_TOOL_REPORTS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: ANALYSIS_TOOL_REPORTS_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchDataThresholdReport() {
  try {
    yield put({ type: DATATHRESHOLD_REPORT_API_LOADING });
    const response = yield call(getDataThresholdReports);
    yield put({ type: DATATHRESHOLD_REPORTS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: DATATHRESHOLD_REPORTS_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchVehicleSoftwareDetails(data) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getVehicleSoftwareDetails, data.data);
    console.log('saga');
    yield put({ type: VEHICLE_SOFTWARE_DETAILS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    console.log(error);
    yield put({ type: VEHICLE_SOFTWARE_DETAILS_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchSoftwareUpdateCriteria() {
  try {
    console.log('SAGA RUN');
    yield put({ type: SOFTWARE_UPDATE_CRITERIA_LOADING_START });
    const response = yield call(getSoftwareUpdateCriteria);
    // console.log(response);
    if (response) {
      yield put({ type: SOFTWARE_UPDATE_CRITERIA_LOADED, response });
    } else {
      yield put({ type: SOFTWARE_UPDATE_CRITERIA_LOADING_FAILED });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: SOFTWARE_UPDATE_CRITERIA_LOADING_FAILED });
  }
}
