import * as types from '../constants';

const initialState = {
  sets: [],
  set: { installationPackages: [], name: '' },
  testSets: [],
  testSet: { installationPackageVersions: [], name: '' },
  loading: false,
  setsLoading: false
};

function setReducer(state = initialState, action) {
  switch (action.type) {
    case types.SETS_LOADED:
      return { ...state, sets: action.response };
    case types.TEST_SETS_LOADED:
      return { ...state, testSets: action.response };
    case types.SET_LOADED:
      return { ...state, set: action.response };
    case types.TEST_SET_LOADED:
      return { ...state, testSet: action.response };
    case types.TEST_SETS_LOADING_START:
      return { ...state, loading: true };
    case types.TEST_SETS_LOADING_END:
      return { ...state, loading: false };
    case types.SETS_LOADING_START:
      return { ...state, setsLoading: true };
    case types.SETS_LOADING_END:
      return { ...state, setsLoading: false };
    default:
      return state;
  }
}

export default setReducer;
