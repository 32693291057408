import { put, call } from 'redux-saga/effects';
import { getSets, getTestSets, getSet, getTestSet } from '../services/setService';
import {
  SETS_FAILED,
  SETS_LOADED,
  TEST_SETS_LOADED,
  TEST_SETS_FAILED,
  SHOW_LOADING,
  HIDE_LOADING,
  SET_LOADED,
  SET_FAILED,
  TEST_SET_LOADED,
  TEST_SET_FAILED,
  TEST_SETS_LOADING_START,
  TEST_SETS_LOADING_END,
  SETS_LOADING_END,
  SETS_LOADING_START
} from '../constants';

export function* fetchSets() {
  try {
    yield put({ type: SETS_LOADING_START });
    const response = yield call(getSets);
    yield put({ type: SETS_LOADED, response });
    yield put({ type: SETS_LOADING_END });
  } catch (error) {
    yield put({ type: SETS_FAILED, error });
    yield put({ type: SETS_LOADING_END });
  }
}

export function* fetchTestSets() {
  try {
    yield put({ type: TEST_SETS_LOADING_START });
    const response = yield call(getTestSets);
    yield put({ type: TEST_SETS_LOADED, response });
    yield put({ type: TEST_SETS_LOADING_END });
  } catch (error) {
    yield put({ type: TEST_SETS_FAILED, error });
    yield put({ type: TEST_SETS_LOADING_END });
  }
}

export function* fetchSet(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getSet, payload.name);
    yield put({ type: SET_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: SET_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchTestSet(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getTestSet, payload.name);
    yield put({ type: TEST_SET_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: TEST_SET_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}
