import React from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import PropTypes from 'prop-types';
import appCss from './styles';
import loginBgImage from '../../assets/images/login-bg.jpg';
import { login } from '../../actions/loginAction';
import MiniSpinner from '../../common/components/Spinner';

const divStyle = {
  color: '#FFFFFF',
  fontSize: '15px'
};

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { username: '', password: '', error: '' };
  }

  login = () => {
    // console.log('login');
    const { username, password } = this.state;
    const { login } = this.props;

    if (!username.length || !password.length) {
      return;
    }
    const data = { username, password };
    login(data);
  };

  forgotpassword = () => {
    // console.log('Forgot Pass word');
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.login(e);
    }
  };

  render() {
    const { isLoginFailed, isLoading } = this.props;
    const { username, password } = this.state;
    return (
      <>
        <Grid container sx={appCss.container} spacing={0.5}>
          <img alt="Login" style={appCss.loginBg} src={loginBgImage} />
        </Grid>
        <Grid container sx={appCss.container} spacing={0.5}>
          <Grid sx={appCss.loginContent}>
            <Typography sx={appCss.title}>SEMS</Typography>
            <Typography sx={appCss.subTitle}>SERVICE MANAGEMENT SYSTEM</Typography>
            <Grid container sx={appCss.fieldContainer}>
              <TextField
                variant="standard"
                size="small"
                sx={appCss.textField}
                id="username"
                InputProps={{ sx: appCss.input }}
                value={username}
                name="username"
                onChange={this.handleInputChange}
                placeholder="Enter username"
                onKeyPress={e => this.handleKeyPress(e)}
                autoComplete="off"
              />
              <TextField
                size="small"
                variant="standard"
                sx={appCss.textField}
                type="password"
                id="password"
                InputProps={{ sx: appCss.input }}
                value={password}
                name="password"
                onChange={this.handleInputChange}
                placeholder="**********"
                onKeyPress={e => this.handleKeyPress(e)}
                autoComplete="off"
              />
            </Grid>
            {isLoginFailed && (
              <Grid container sx={appCss.fieldContainer}>
                <Typography sx={appCss.error}>Invalid username or password</Typography>
              </Grid>
            )}
            {/* <Grid style={divStyle}>
              <Button>
                <Link to="/sems/forgot-password" style={divStyle}>
                  Forgot your password?
                </Link>
              </Button>
            </Grid> */}

            <Fab
              disabled={isLoading}
              color="primary"
              size="large"
              sx={appCss.loginBtn}
              variant="extended"
              onClick={this.login}
            >
              LOGIN
              {isLoading && <MiniSpinner />}
            </Fab>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoginFailed: state.loginReducer.isLoginFailed,
    isLoading: state.loginReducer.isLoading
  };
};

Login.propTypes = {
  isLoginFailed: PropTypes.bool,
  login: PropTypes.func
};

export default connect(mapStateToProps, { login })(Login);
