import { store } from '..';

// const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const API_BASE_URL = '/';
const BASE_PATH_SECURITY = API_BASE_URL;
export const IMG_BASE_URL = process.env.REACT_APP_IMG_ENDPOINT;
export const LOGIN_URL = `${BASE_PATH_SECURITY}semss/security/v1/api/login`;

//export const LOGIN_URL = `${BASE_PATH_SECURITY}security/v1/api/login`;
// export const LOGIN_URL = `https://admin.qa.sems.ws/semss/security/v1/api/login`;

export const VALIDATE_TOKEN = `${BASE_PATH_SECURITY}semss/security/v1/api/validateAuthToken`;
// qa validate token url
// export const VALIDATE_TOKEN = `${BASE_PATH_SECURITY}/security/v1/api/validateToken`;
export const GET_CURRENT_TENANT = `${API_BASE_URL}portalapi/v1/currentTenant`;
export const UPDATE_SEMS_CLIENT = `${API_BASE_URL}semss/security/v1/api/updateSemClient`;
export const HELP_FILE_URL = `${API_BASE_URL}portal/resources/app/SEMS Portal Manual_V2.9.pdf`;
export const GET_CLAIMS = `${API_BASE_URL}semss/security/v1/services/`;

// export const GET_PORTAL_VERSION = `${API_BASE_URL}portal/v1/packageversion`;
//DEPRECATED 02-06-2023
export const GET_PORTAL_API_VERSION = `${API_BASE_URL}portalapi/v1/packageversion`;
// export const GET_API_VERSION = `${API_BASE_URL}api/v1/packageversion`;
// export const GET_FILESHARE_VERSION = `${API_BASE_URL}fileshare/v1/packageversion`;

export const GET_APPLICATIONS = `${API_BASE_URL}portalapi/v1/installation_package/apps/`;
export const GET_APPLICATIONS_MULTIAPK = `${API_BASE_URL}portalapi/v1/context/allapps`;
export const GET_TEST_SET_APP = `${API_BASE_URL}portalapi/v1/installation_package/testapps/`;
export const DELETE_APPLICATION = `${API_BASE_URL}portalapi/v1/admin/installation_package/app/:name`;
export const UPDATE_CONTEXT_NAME = `${API_BASE_URL}portalapi/v1/installation_package/updatecontextname/:contextName/:installationPackageId`;

export const GET_APPLICATION = `${API_BASE_URL}portalapi/v1/installation_package/app/`;
export const GET_APPLICATION_VERSION = `${API_BASE_URL}portalapi/v1/installation_package/app/:name/version`;
export const SEND_EXTERNAL_REFERENCE_REQUEST = `${API_BASE_URL}portalapi/v1/installation_package/refreshfidoupload/type/:type/version/:id`;
export const UPLOAD_APP_SEM1 = `${API_BASE_URL}portalapi/v1/installation_package/app/version`;
export const UPLOAD_APP_SEM2 = `${API_BASE_URL}portalapi/v1/installation_package/app/sem2version/sem2`;
export const UPLOAD_APP_SID2 = `${API_BASE_URL}portalapi/v1/installation_package/s3/app/version`;
export const VCE_UPLOAD_APP_SEM2 = `${API_BASE_URL}portalapi/v1/installation_package/app/sem2version/`;
export const PENTA_UPLOAD_APP = `${API_BASE_URL}portalapi/v1/installation_package/s3/app/version`;
// only for vbc use this url
export const VBC_UPLOAD_APP_SEM2 = `${API_BASE_URL}portalapi/v1/installation_package/app/sem2version/sem2`;
export const GET_MAP_DOWNLOAD = `${API_BASE_URL}portalapi/v1/admin/installation_package/maps/`;
// export const GET_MAP_DOWNLOAD = `https://portal.qa.sems.ws/portalapi/v1/admin/installation_package/maps/`;
export const GET_VEHICLE_STATUS_REPORT = `${API_BASE_URL}portalapi/v1/vehiclestatusreport`;
// export const GET_VEHICLE_STATUS_REPORT = `https://portal.qa.sems.ws/portalapi/v1/vehiclestatusreport`;

export const DELETE_APP_VERSION = `${API_BASE_URL}portalapi/v1/admin/installation_package/app/:name/version/:version`;
export const UPDATE_APP_VERSION = `${API_BASE_URL}portalapi/v1/installation_package/app/:name/version/:version`;
export const GET_EXPRESSIONS = `${API_BASE_URL}portalapi/v1/expression/installation_package/`;
export const UPDATE_EXPRESSION = `${API_BASE_URL}portalapi/v1/expression`;
export const DELETE_EXPRESSION = `${API_BASE_URL}portalapi/v1/admin/expression/:id`;

export const GET_METADATA_ALL = `${API_BASE_URL}portalapi/v1/metadata/all/FIRMWARE,HARDWARE,MAP`;
export const GET_METADATA_VEHICLE_REPOSITORY = `${API_BASE_URL}portalapi/v1/metadata/all/VEHICLE_REPOSITORY`;
export const GET_METADATA_ALL_VALUES = `${API_BASE_URL}portalapi/v1/metadata/values`;

export const GET_MAPS = `${API_BASE_URL}portalapi/v1/installation_package/mapForHome/`;
export const GET_TEST_SET_MAPS = `${API_BASE_URL}portalapi/v1/installation_package/testmaps/`;
export const GET_MAP = `${API_BASE_URL}portalapi/v1/installation_package/map/`;
export const GET_MAP_VERSION = `${API_BASE_URL}portalapi/v1/installation_package/map/:name/version`;
export const ADD_MAP_VERSION = `${API_BASE_URL}portalapi/v1/installation_package/addmap/version?fileName=:fileName&name=:name&version-name=:versionName&version-code=:versionCode&tenant=:tenant`;
export const CREATE_MAP = `${API_BASE_URL}portalapi/v1/installation_package/map`;
export const DELETE_MAP = `${API_BASE_URL}portalapi/v1/admin/installation_package/map/:name`;
export const GET_AWS_DETAILS = `${API_BASE_URL}portalapi/v1/awsdetails?name=:name&version-name=:versionName&version-code=:versionCode`;
export const ADD_MAP_VERSION_SEM2 = `${API_BASE_URL}portalapi/v1/installation_package/addmap/saveSemsMapDetails?fileName=:fileName&name=:name&version-name=:versionName&version-code=:versionCode&tenant=:tenant&size=:size&region=:region&hashcode=:hashCode`;
export const GET_MAP_VERSION_METADATA = `${API_BASE_URL}portalapi/v1/installation_package/map/:name/version/:versionCode/metadata`;
export const SAVE_MAP_VERSION_METADATA = `${API_BASE_URL}portalapi/v1/installation_package/map/:name/version/:versionCode/metadata`;
export const GET_MAPNAMES_FOR_UPLOAD = `${API_BASE_URL}portalapi/v1/installation_package/getMapNamesForUpload`;
export const UPDATE_MAP_VERSTION = `${API_BASE_URL}portalapi/v1/installation_package/map/version/:version/:semValue`;
export const DELETE_MAP_VERSION = `${API_BASE_URL}portalapi/v1/admin/installation_package/map/:name/version/:version`;
export const SAVE_SEMS_MAP_DETAILS =
  `${API_BASE_URL}portalapi/v1/installation_package/addmap/saveSemsMapDetails/sem2?fileName=:fileName&` +
  `name=:name&version-name=:versionName&version-code=:versionCode&tenant=:tenant&size=:size&region=:region&hashcode=:hashCode&user=:user`;
//DEPRECATED -NOT REQUIRED FOR VCE
export const VCE_MAP_SAVE_SEM2_MAP_DETAILS =
  `${API_BASE_URL}portalapi/v1/installation_package/addmap/saveSemsMapDetails/DCM1?fileName=:fileName&` +
  `name=:name&version-name=:versionName&version-code=:versionCode&tenant=:tenant&size=:size&region=:region&hashcode=:hashCode&user=:user`;
export const GET_AWS_S3_DETAILS = `${API_BASE_URL}portalapi/v1/icon/awsdetails`;
export const GET_FIRMWARES = `${API_BASE_URL}portalapi/v1/installation_package/firmwareForHome/`;
export const GET_TEST_SET_FIRMWARES = `${API_BASE_URL}portalapi/v1/installation_package/testfirmwares/`;
export const GET_FIRMWARE = `${API_BASE_URL}portalapi/v1/installation_package/firmware/`;
export const GET_FIRMWARE_VERSION = `${API_BASE_URL}portalapi/v1/installation_package/firmware/:name/version`;
export const CREATE_FIRMWARE = `${API_BASE_URL}portalapi/v1/installation_package/firmware`;
export const MIGRATE_CLUP_TO_XML = `${API_BASE_URL}portalapi/v1/installation_package/clupmigratebinarytoxml/version/:id`;

export const UPLOAD_FIRMWARE_SEM1 = `${API_BASE_URL}portalapi/v1/installation_package/firmware/version`;
export const UPLOAD_FIRMWARE_SEM2 = `${API_BASE_URL}portalapi/v1/installation_package/firmware/sem2version/sem2`;
export const VCE_UPLOAD_FIRMWARE_SEM2 = `${API_BASE_URL}portalapi/v1/installation_package/firmware/sem2version/ICM1`;
export const VCE_UPLOAD_FIRMWARE_SID2 = `${API_BASE_URL}portalapi/v1/installation_package/s3/firmware/version`;
export const PENTA_UPLOAD_FIRMWARE = `${API_BASE_URL}portalapi/v1/installation_package/s3/firmware/version`;
export const DELETE_FIRMWARE_VERSION = `${API_BASE_URL}portalapi/v1/admin/installation_package/firmware/:name/version/:version`;
export const GET_FIRMWARE_VERSION_METADATA = `${API_BASE_URL}portalapi/v1/installation_package/firmware/:name/version/:versionCode/metadata`;
export const SAVE_FIRMWARE_VERSION_METADATA = `${API_BASE_URL}portalapi/v1/installation_package/firmware/:name/version/:versionCode/metadata`;
export const UPDATE_FIRMWARE_VERSION = `${API_BASE_URL}portalapi/v1/installation_package/firmware/version/:version`;
export const DELETE_FIRMWARE = `${API_BASE_URL}portalapi/v1/admin/installation_package/firmware/:name`;
export const GET_FIRMWARE_DELTAFIRMWARE = `${API_BASE_URL}portalapi/v1/installation_package/deltafirmwares/`;
export const SAVE_FIRMWARE_DELTAFIRMWARE = `${API_BASE_URL}portalapi/v1/installation_package/updatedeltafirmwares`;
export const DELETE_DELTAFIRMWARE = `${API_BASE_URL}portalapi/v1/admin/installation_package/deltafirmwares/`;
export const SAVE_DELTAFIRMWARE = `${API_BASE_URL}portalapi/v1/installation_package/deltafirmwares`;
export const GET_FIRMWARE_ARTIFACTORY_URL = `${API_BASE_URL}sems/getartifacts?tenant=gtt&sem=sem2`;
export const UPLOAD_FIRMWARE_ARTIFACTORY_URL = `${API_BASE_URL}portalapi/v1/getFirmwareARtifactory`;

export const GET_META_DATAS = `${API_BASE_URL}portalapi/v1/metadataForHome`;
export const GET_META_DATA_VALUES = `${API_BASE_URL}portalapi/v1/metadata/:property/values`;
export const GET_META_DATA_UNINITIALIZED_VALUES = `${API_BASE_URL}portalapi/v1/metadata/:property/uninitialized_values`;
export const SAVE_META_DATA = `${API_BASE_URL}portalapi/v1/metadata`;
export const SAVE_META_DATA_VALUES = `${API_BASE_URL}portalapi/v1/metadata/:name/values`;
export const UPDATE_METADATA_PACKAGE_VERSION_DETAILS = `${API_BASE_URL}portalapi/v1/metadataPackageExpression`;

export const DELETE_META_DATA = `${API_BASE_URL}portalapi/v1/admin/metadata/:property`;
export const DELETE_META_DATA_VALUE = `${API_BASE_URL}portalapi/v1/admin/metadata/:name/values/:value`;
export const DELETE_VERSION_META_DATA = `${API_BASE_URL}portalapi/v1/admin/installation_package/:type/:name/version/:versionCode/metadata/:id`;

export const GET_SET_META_DATAS = `${API_BASE_URL}portalapi/v1/metadata/all/VEHICLE_REPOSITORY,FIRMWARE`;
export const GET_SET_EXPRESSIONS = `${API_BASE_URL}portalapi/v1/expression/installation_package_set/`;

export const GET_SETS = `${API_BASE_URL}portalapi/v1/installationPackageSetForHome/`;
export const GET_SET = `${API_BASE_URL}portalapi/v1/installationPackageSet/`;
export const GET_TEST_SETS = `${API_BASE_URL}portalapi/v1/testInstallationPackageSetForHome/`;
export const GET_TEST_SET = `${API_BASE_URL}portalapi/v1/testInstallationPackageSet/`;
export const CREATE_TEST_SET = `${API_BASE_URL}portalapi/v1/testInstallationPackageSet`;
export const VALIDATE_TEST_VEHICLE = `${API_BASE_URL}portalapi/v1/validateTestvehicle/:id`;
export const DELETE_TEST_SET = `${API_BASE_URL}portalapi/v1/admin/testInstallationPackageSet/:name`;
export const GET_TEST_SET_APP_VERSION = `${API_BASE_URL}portalapi/v1/installation_package/:type/:name/testversion`;

export const CREATE_SET = `${API_BASE_URL}portalapi/v1/installationPackageSet`;
export const UPDATE_SET = `${API_BASE_URL}portalapi/v1/installationPackageSet/update`;
export const DELETE_SET = `${API_BASE_URL}portalapi/v1/admin/installationPackageSet/:name`;

export const GET_BUSINESS_REPORTS = `${API_BASE_URL}portalapi/v1/businessreport`;
export const GET_TECHNICAL_REPORT = `${API_BASE_URL}portalapi/v1/technicalreport/`;
export const GET_DENIED_ACCESS_REPORTS = `${API_BASE_URL}security/v1/deniedAccessReport`;
export const GET_INSTALLATION_STATUS_REPORTS = `${API_BASE_URL}portalapi/v1/installationstatusreport`;
export const GET_AUDIT_LOG_REPORTS = `${API_BASE_URL}portalapi/v1/auditLog`;
export const GET_AUDIT_LOG_REPORTS_USER = `${API_BASE_URL}security/v1/auditLogSecurity`;

export const GET_OTA_LOCK_REPORTS = `${API_BASE_URL}portalapi/v1/otalockreport/`;
export const GET_OTA_LOCK_REPORTS_BY_CHASSISID = `${API_BASE_URL}portalapi/v1/otalockReport/`;
export const DELETE_OTA_LOCK_REPORTS = `${API_BASE_URL}portalapi/v1/otalockreport/delete`;

export const GET_ANALYSIS_TOOL_REPORTS = `${API_BASE_URL}portalapi/v1/analysistoolvehiclemetadata`;
export const GET_VEHICLE_SOFTWARE_DETAILS = `${API_BASE_URL}portalapi/v1/analyzesoftwaredetails/`;
export const GET_VEHICLE_SOFTWARE_DETAILS_JSON = `${API_BASE_URL}portalapi/v1/analyzeSoftwareDetailsForJsonInput/`;

export const GET_DATA_THRESHOLD_REPORTS = `/sus/getBusyVehicles/`;

export const GET_SOFTWARE_UPDATE_CRITERIA = `${API_BASE_URL}portalapi/v1/updateCriteriaReport`;
export const GET_SPAPI_DETAILS = `${API_BASE_URL}portalapi/v1/spapi/version/`;

export const UNMARK_AS_TEST_VEHICLE = `${API_BASE_URL}portalapi/v1/DeleteTestVehicle/:id?imeiNumber=`;
export const MARK_AS_TEST_VEHICLE = `${API_BASE_URL}portalapi/v1/AddTestVehicle/:id?imeiNumber=`;
export const GET_TESTVEHICLES = `${API_BASE_URL}portalapi/v1/TestVehicles`;

export const REQUEST_ALERT_REPORT = `${API_BASE_URL}portalapi/v1/RequestAlertReport`;
export const DOWNLOAD_ALERT_REPORT = `${API_BASE_URL}portalapi/v1/DownloadAlertReport`;

export const CUSTOMIZED_REPORT = `${API_BASE_URL}portalapi/v1/CustomReport`;

export const GET_USERS = `${API_BASE_URL}semss/security/v1/admin/api/users/`;
export const GET_USER_ROLES = `${API_BASE_URL}semss/security/v1/admin/api/role/`;
export const CREATE_USER = `${API_BASE_URL}semss/security/v1/admin/api/createUser`;
export const DELETE_USER = `${API_BASE_URL}semss/security/v1/admin/api/deleteuser/`;
export const UPDATE_USER = `${API_BASE_URL}semss/security/v1/admin/api/updateuser`;

/* EXPORT-TO-USB */
export const GET_USB_DATA = `${API_BASE_URL}portalapi/v1/usbdata/`;
export const GET_VEHICLE_METADATAS = `${API_BASE_URL}portalapi/v1/vehiclemetadatas/`;
export const GET_VEHICLE_METADATA_VALUES = `${API_BASE_URL}portalapi/v1/vehiclemetadatavalues/`;
export const UPDATE_USB = `${API_BASE_URL}portalapi/v1/usbupdate`;
export const PENTA_USB_UPDATE = `${API_BASE_URL}portalapi/v1/usbupdate/packageType/`;
export const UPLOAD_JSON = `${API_BASE_URL}portalapi/v1/jsonupload`;

export const EXPORT_FOR_TYPE = `${API_BASE_URL}portalapi/v1/exportexcelfortype/:type/successmsg/:message`;
export const EXPORT_EXCEL = `${API_BASE_URL}portalapi/v1/exportexcel/:package/type/:type/successmsg/:message`;

export const GET_SEM_SERIAL_NUMBER = `${API_BASE_URL}portalapi/v1/semserialnumber`;
export const UPDATE_SEM_SERIAL_NUMBER = `${API_BASE_URL}portalapi/v1/semserialnumbers`;

export const GET_PRODUCTLIST_FOR_APPROVAL = `/portalapi/v1/reviewrequests/`;
export const APPROVE_REJECT_ENDPOINT = `/portalapi/v1/reviewrequest`;
export const REQUEST_FOR_REVIEW = `/portalapi/v1/requestforreview`;

export const LIST_ALL_CONTEXT = `portalapi/v1/context/allContexts`;
export const SAVE_NEW_CONTEXT = `/portalapi/v1/context/saveContext`;
export const DELET_CONTEXT = `/portalapi/v1/context/deleteContext/`;
export const GET_ALLCONTEXT_PACKAGES = `portalapi/v1/context/getAllPackages`;
export const GET_GROUPBY_CONTEXT_ID = `portalapi/v1/context/getGroupByContextId`;
export const UPDATE_IS_REQUIRED = `/portalapi/v1/context/updateIsRequired`;
export const SAVE_GROUP_TO_CONTEXT = `/portalapi/v1/context/saveGroupToContext`;
export const DELETE_GROUP_CONTEXT = `/portalapi/v1/context/deleteContextGroup`;
export const GET_GROUP_DETAILS = `portalapi/v1/context/getGroupDetails`;
export const UPDATE_IS_MANDATORY = `/portalapi/v1/context/updateIsMandatory`;
export const DELETE_GROUP_PACKAGE = `/portalapi/v1/context/deleteGroupPackage`;

export const GET_GROUP_EXPRESSION = `/portalapi/v1/groupexpression/context_group`;
export const UPDATE_GROUP_EXPRESSION = `/portalapi/v1/groupexpression`;
export const DELETE_GROUP_EXPRESSION = `/portalapi/v1/admin/groupexpression`;

// https://localhost:7943/security/v1/admin/api/reviewers/vce
export const IN_PROD_STATUS = `/portalapi/v1/approvalstatus`;
export const SEM1 = `sem1`;

/* PASSWORD RESET */
// /v1/admin/api/updateuser
// change it to correct URL
export const PASSWORD_RESET = `${API_BASE_URL}semss/security/v1/api/changePassword`;

// portalapi/v1/exportexcel/sem_v1.volvo_trucks.connectedbodies/type/app/successmsg/all
/* EXPORT-TO-USB */

export const getSemType = () => {
  //const semType = localStorage.getItem('sem');
  const newState = store.getState();
  const semType = newState.loginReducer.semType || localStorage.getItem('sem');
  if (semType != null) {
    if (semType == 'undefined') {
      return 'sem1';
    }
    return semType;
  }
  return 'sem1';
};

export const getSemDetails = () => {
  const newState = store.getState();
  const semType = newState.loginReducer.semType || localStorage.getItem('sem');
  return semType ? semType : 'sem1';
};

export const getSemTypeDetails = () => {
  const newState = store.getState();
  const semType = newState.loginReducer.vceSemType || localStorage.getItem('semType');
  return semType ? semType : 'sem1';
};

export const getTenantDetails = () => {
  const newState = store.getState();
  return newState.loginReducer.tenant.handle || localStorage.getItem('tenantHandle');
  //return localStorage.getItem('tenantHandle');
};

export const getUserDetails = () => {
  const newState = store.getState();
  return newState.loginReducer.user.username || localStorage.getItem('user');
};

export const getEnvDetails = () => {
  const domain = window.location.host;
  if (domain === 'admin.sems.ws' || domain === 'admin-us.sems.ws') {
    return 'PROD';
  } else if (domain === 'admin.qa.sems.ws' || domain === 'admin-us.qa.sems.ws') {
    return 'QA';
  } else if (domain === 'admin.iot1.sems.ws' || domain === 'admin-us.iot1.sems.ws') {
    return 'IOT1';
  } else if (domain === 'admin.test.sems.ws' || domain === 'admin-us.test.sems.ws') {
    return 'TEST';
  } else {
    return 'LOCAL';
  }
};

export const getThemeColor = () => {
  const semType = getSemTypeDetails();
  switch (semType) {
    case 'sem2':
      return '#387273';
    case 'sem2.5':
      return '#597991';
    case 'ICM1':
      return '#597991';
    case 'SID2':
      return '#387273';
    default:
      return '#1C45AF';
  }
};
