import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import applicationReducer from './applicationReducer';
import mapReducer from './mapReducer';
import firmWareReducer from './firmWareReducer';
import setReducer from './setReducer';
import businessReportReducer from './businessReportReducer';
import technicalReportReducer from './technicalReportReducer';
import analysisToolReportReducer from './analysisToolReducer';
import metaDataReducer from './metaDataReducer';
import deniedAccessReportReducer from './deniedAccessReportReducer';
import userReducer from './userReducer';
import installationStatusReportReducer from './installationStatusReportReducer';
import auditLogReducer from './auditLogReducer';
import otaLockReducer from './otaLockReducer';
import expressionReducer from './expressionReducer';
import downloadMapReducer from './downloadMapReducer';
import vehicleStatusReducer from './vehicleStatusReducer';
import dataThresholdReducer from './dataThresholdReducer';
import softwareUpdateCriteriaReducer from './softwareUpdateCriteriaReducer';

const appReducer = combineReducers({
  loginReducer,
  applicationReducer,
  mapReducer,
  firmWareReducer,
  setReducer,
  businessReportReducer,
  technicalReportReducer,
  analysisToolReportReducer,
  metaDataReducer,
  deniedAccessReportReducer,
  userReducer,
  installationStatusReportReducer,
  auditLogReducer,
  otaLockReducer,
  expressionReducer,
  downloadMapReducer,
  vehicleStatusReducer,
  dataThresholdReducer,
  softwareUpdateCriteriaReducer
  // router: connectRouter(history)
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    const analysisIdList = JSON.parse(localStorage.getItem('analysisIdList'));
    localStorage.clear();
    localStorage.setItem('analysisIdList', JSON.stringify(analysisIdList)); //this will be removed in future
    if (action.payload) {
      window.location.href = action.payload;
    } else {
      window.location.href = '#/login';
    }
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
