import * as types from '../constants';

const initialState = {
  users: [],
  user: undefined,
  userRoles: [],
  loading: false
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.USERS_LOADED:
      return { ...state, users: action.response };
    case types.USER_ROLES_LOADED:
      return { ...state, userRoles: action.response };
    case types.USERS_LOADING_START:
      return { ...state, loading: true };
    case types.USERS_LOADING_END:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default userReducer;
