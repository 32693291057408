import { fork } from 'redux-saga/effects';
import * as watchers from './watchers';

export default function* startWatching() {
  yield fork(watchers.watchLogin);
  yield fork(watchers.watchRefreshCredentials);

  yield fork(watchers.watchApplications);
  yield fork(watchers.watchApplication);
  yield fork(watchers.watchApplicationVersion);
  yield fork(watchers.watchDeleteApplication);

  yield fork(watchers.watchMaps);
  yield fork(watchers.watchMap);
  yield fork(watchers.watchMapVersion);
  yield fork(watchers.watchCreateMap);
  yield fork(watchers.watchDeleteMap);
  yield fork(watchers.watchMapNamesForUpload);

  yield fork(watchers.watchFirmWares);
  yield fork(watchers.watchFirmware);
  yield fork(watchers.watchFirmwareVersion);
  yield fork(watchers.watchCreateFirmware);

  yield fork(watchers.watchMetaDatas);
  yield fork(watchers.watchMetadataValues);
  yield fork(watchers.watchMetadataUninitializedValues);
  yield fork(watchers.watchAllMetaDatas);
  yield fork(watchers.watchAllMetadataValues);
  yield fork(watchers.watchAllUpdatedMetadata);

  yield fork(watchers.watchSets);
  yield fork(watchers.watchSet);

  yield fork(watchers.watchTestSets);
  yield fork(watchers.watchTestSet);

  yield fork(watchers.watchBusinessReports);
  yield fork(watchers.watchTechnicalReport);
  yield fork(watchers.watchAnaysisToolReport);
  yield fork(watchers.watchDataThresholdReport);
  yield fork(watchers.watchVehicleSoftwareDetails);

  yield fork(watchers.watchDeniedAccessReports);
  yield fork(watchers.watchInstallationStatusReports);
  yield fork(watchers.watchAuditLogReports);
  yield fork(watchers.watchOtaLockReports);
  yield fork(watchers.watchUsers);
  yield fork(watchers.watchUserRoles);
  yield fork(watchers.watchVehicleStatusReport);
  yield fork(watchers.watchSoftwareUpdateCriteria);

  yield fork(watchers.watchExpressions);
  yield fork(watchers.watchUpdateSemsClient);

  yield fork(watchers.watchAWSS3Config);

  yield fork(watchers.watchDownloadMaps);
}
