const CryptoJS = require('crypto-js');

const key = 'dsdff--Na--swe--ve--wfwf--ee--dfw--n--wfwf';

export const encryptToHex = data => {
  const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  const cipherb64Text = CryptoJS.enc.Base64.parse(cipherText);
  const cipherHex = cipherb64Text.toString(CryptoJS.enc.Hex);
  return cipherHex;
};

export const decryptFromHex = cipherHex => {
  const reb64Text = CryptoJS.enc.Hex.parse(cipherHex);
  const bytes = reb64Text.toString(CryptoJS.enc.Base64);
  const decryptText = CryptoJS.AES.decrypt(bytes, key);
  const plainText = JSON.parse(decryptText.toString(CryptoJS.enc.Utf8));
  return plainText;
};
export const getCryptoMethod = ()=>{
  return CryptoJS;
}
