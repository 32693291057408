import { put, call } from 'redux-saga/effects';
import AWS from 'aws-sdk';
import util from 'util';

import {
  getApplications,
  getApplication,
  getApplicationVersion,
  getAWSS3Configuration,
  deleteApplication as deleteItem
} from '../services/applicationService';
import {
  APPLICATIONS_LOADED,
  APPLICATIONS_FAILED,
  SHOW_LOADING,
  HIDE_LOADING,
  APPLICATION_LOADED,
  APPLICATION_FAILED,
  APPLICATION_VERSION_FAILED,
  APPLICATION_VERSION_LOADED,
  AWS_S3_SEM2_CONFIG,
  AWS_S3_SEM2_CONFIG_FAILED,
  AWS_S3_SEM2_CONFIG_LOADED,
  APPLICATIONS_LOADING_START,
  APPLICATIONS_LOADING_END,
  APPLICATION_VERSION_LOADING_START,
  APPLICATION_VERSION_LOADING_END
} from '../constants';

export function* fetchApplications(payload) {
  try {
    yield put({ type: APPLICATIONS_LOADING_START });
    const response = yield call(getApplications, payload.appListType);
    yield put({ type: APPLICATIONS_LOADED, response });
    // const response1 = yield call(getAWSS3Configuration);
    // console.log(response1.map(d=>getS3Image(d,response1)))
    // const response3 = yield call()
    // console.log('response3:::::',response3)
    yield put({ type: APPLICATIONS_LOADING_END });
  } catch (error) {
    yield put({ type: APPLICATIONS_FAILED, error });
    yield put({ type: APPLICATIONS_LOADING_END });
  }
}

export function* fetchApplication(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getApplication, payload.name);
    yield put({ type: APPLICATION_LOADED, response });
  } catch (error) {
    yield put({ type: APPLICATION_FAILED, error });
  }
}

export function* fetchApplicationVersion(payload) {
  try {
    yield put({ type: APPLICATION_VERSION_LOADING_START });
    const response = yield call(getApplicationVersion, payload.name);
    yield put({ type: APPLICATION_VERSION_LOADED, response });
    yield put({ type: APPLICATION_VERSION_LOADING_END });
  } catch (error) {
    yield put({ type: APPLICATION_VERSION_FAILED, error });
    yield put({ type: APPLICATION_VERSION_LOADING_END });
  }
}

export function* fetchAWSS3Configuration() {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getAWSS3Configuration);
    yield put({ type: AWS_S3_SEM2_CONFIG_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: APPLICATION_VERSION_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export const getS3Image = async (data2, data1) => {
  const credentials = new AWS.Credentials({ ...data1 });
  const s3 = new AWS.S3({
    credentials,
    region: data1.clientRegion
  });
  s3.getObject = util.promisify(s3.getObject);

  const params = {
    Bucket: data1.bucketName,
    Key: `apps/${data2.iconUrl}`
  };
  const res = await s3.getObject(params);
  const arrayBufferView = new Uint8Array(res.Body);
  const blob = new Blob([arrayBufferView], { type: res.ContentType });
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);
  return imageUrl;
};

export function* deleteApplication(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const deleteResponse = yield call(deleteItem, payload.name);
    const response = yield call(getApplications);
    yield put({ type: APPLICATIONS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: HIDE_LOADING });
  }
}
