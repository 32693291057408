export const apiErrorHandler = apiResponse => {
  const response = {
    statusCode: null,
    errorFlag: false,
    errorMessage: '',
    data: []
  };

  response.statusCode = apiResponse.status;

  switch (apiResponse.status) {
    case 0:
      response.errorMessage = 'User Cancelled';
      response.errorFlag = true;
      break;

    case 400:
      response.errorMessage = apiResponse.data ? apiResponse.data.message : 'Invalid Request';
      response.errorFlag = true;
      break;

    case 404:
      response.errorMessage = !apiResponse.data
        ? 'Not Found'
        : apiResponse.data.message
        ? apiResponse.data.message
        : apiResponse.data;
      response.errorFlag = true;
      break;

    case 406:
      response.errorMessage = 'Unable to upload file, Since it is Blacklisted';
      response.errorFlag = true;
      break;

    case 409:
      response.errorMessage = 'Already Exists';
      response.errorFlag = true;
      break;

    case 415:
      response.errorMessage = 'Unsupported Media type';
      response.errorFlag = true;
      break;

    case 500:
      response.errorMessage = 'Internal Service Error';
      response.errorFlag = true;
      break;

    default:
      response.data = apiResponse.data;
  }

  return response;
};
