import React from 'react';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { getThemeColor } from '../../services/apis';

const defaultContainerStyle = {
  display: 'flex',
  height: '98vh',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center'
};
export const MainSuspenseComponent = ({ message, containerStyle }) => (
  <>
    {/* <div
        style={{
          // display: 'flex',
          width: '100%',
  
          justifyContent: 'center',
          padding: 'auto',
          margin: 'auto',
          alignItems: 'center'
          // padding: '70px'
        }}
      >
        <LinearProgress sx={{ height: '3px' }}></LinearProgress>
      </div> */}
    <div style={containerStyle ? containerStyle : defaultContainerStyle}>
      <Typography variant="h3" sx={{ paddingBottom: '20px', color: getThemeColor() }}>
        {message ? message : 'Welcome to Service Management System'}
      </Typography>

      <SubSuspenseComponent />
    </div>
  </>
);

export const SubSuspenseComponent = () => (
  <div style={{ width: '300px', color: getThemeColor() }}>
    <LinearProgress color="inherit" sx={{ height: '20px', borderRadius: '20px' }}></LinearProgress>
  </div>
);
