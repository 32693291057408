import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { connect } from 'react-redux';
import { Route, HashRouter as Router, Routes, Navigate } from 'react-router-dom';
import { Error404 } from './common/components/ErrorComponent';
import SessionTimeout from './common/components/SessionTimeOut';
import { logout, refreshCredentials } from './actions/loginAction';
import { encryptToHex } from './common/utils/crypto';
import packageJson from '../package.json';
// import { history } from './store/configureStore';
// import ComponentLoading from './tools/ComponentLoading';
import Login from './views/login';
// import { Suspense } from 'react';
// import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';
import { MainSuspenseComponent } from './views/components/main-suspense';
// import ForgotPassword from './views/forgot-password';
// import { appendTenant } from './utility/appendTenant';
// import { getApiHeaderConfig } from './common/components/ApiConfig';
// import { Box } from '@mui/material';
const AppLayout = React.lazy(() => import('./views/layout/app-layout'));

const clearOldCache = () => {
  if ('caches' in window) {
    caches.keys().then(names => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      });
    });
  }

  // localStorage.clear();
  localStorage.setItem('appVersion', packageJson.version);
  window.location.reload(true);
};

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    axios.interceptors.request.use(
      config => {
        config.validateStatus = () => {
          return true;
        };
        config.headers['X-AUTH-TOKEN'] = localStorage.getItem('token');
        // config.headers['Access-Control-Allow-Headers']= '*';
        //  config.headers['userName'] = localStorage.getItem('user');
        //  config.headers['tenant']= localStorage.getItem('tenant');
        return config;
      },
      error => {
        return Promise.reject(error.message);
      }
    );

    axios.interceptors.response.use(
      response => {
        if (response.status === 401 && window.location.href.indexOf('#/login') < 0) {
          this.setState({ open: true });
        }
        return response;
      },
      error => {
        if (error.response) {
          if (error.response.status === 401 && window.location.href.indexOf('#/login') < 0) {
            this.setState({ open: true });
          }
        }
        if (error.response && error.response.data) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
      }
    );
    this.state = {
      open: false
    };
  }

  handleClose = () => {
    const encryptString = encryptToHex(window.location.hash);
    const url = `index.html#/login?redirect=${encryptString}`;
    this.props.logout(url);
    this.setState({ open: false });
  };

  componentWillMount = async () => {
    if (localStorage.getItem('token') && !this.props.isLoggedIn) {
      //console.log(localStorage.getItem('token'));
      await this.props.refreshCredentials();
    }

    const version = localStorage.getItem('appVersion');
    if (packageJson.version !== version) {
      console.log('Reload Files');
      clearOldCache();
    }
  };

  render() {
    const { open } = this.state;
    //To be removed after method is successful
    // console.log('Old version : ' + localStorage.getItem('appVersion'));
    // console.log('New version : ' + packageJson.version);
    // return <MainSuspenseComponent />;

    return (
      <>
        {/* <ConnectedRouter history={history}> */}
        <Router>
          <React.Suspense fallback={<MainSuspenseComponent />}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              maxSnack={5}
              autoHideDuration={3000}
            >
              <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/login" name="SEMS Login" element={<Login />} />
                {/* <Route
                  path="/sems/forgot-password"
                  name="SEMS Forgot Password"
                  render={props => <ForgotPassword {...props} />}
                /> */}
                {/* <PrivateRoute path="/sems" element={AppLayout} /> */}
                <Route path="/sems/*" element={<AppLayout />} />

                <Route path="*" component={Error404} />
              </Routes>
            </SnackbarProvider>
            <SessionTimeout modalOpen={open} handleClose={this.handleClose} />
          </React.Suspense>
        </Router>
        {/* </ConnectedRouter> */}
        {/* <LoadingOverlay asctive={this.props.isLoading} spinner text="Loading..." /> */}
      </>
    );
  }
}

function PrivateRoute({ element, ...rest }) {
  return <Route {...rest} render={props => React.createElement(element, props)} />;
}

const mapStateToProps = state => {
  return {
    isLoading: state.loginReducer.isLoading,
    isLoggedIn: state.loginReducer.isLoggedIn
  };
};

export default connect(mapStateToProps, { logout, refreshCredentials })(App);
