import { getSemDetails } from './services/apis';

export const sort = (list, prop) => {
  list.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
};

export const sortVersions = list => {
  list.sort((a, b) => (a.versionCode > b.versionCode ? -1 : 1));
};

export const expressionOperators = [
  {
    name: 'Equal',
    op: 'EQ'
  },
  {
    name: 'Not equal',
    op: 'NEQ'
  },
  {
    name: 'Less than or equal',
    op: 'LTE'
  },
  {
    name: 'Greater than or equal',
    op: 'GTE'
  }
];

export const setExpressionOperators = [
  {
    name: 'Equal',
    op: 'EQ'
  },
  {
    name: 'Not equal',
    op: 'NEQ'
  }
];

export const curationStatuses = [
  {
    key: 'UPLOADED',
    value: 'Uploaded'
  },
  {
    key: 'READY_FOR_TEST',
    value: 'Ready For Test'
  },
  {
    key: 'PRE_PRODUCTION',
    value: 'Pre Production'
  },
  {
    key: 'IN_PRODUCTION',
    value: 'In Production'
  }
];

export const getCurationStatus = key => {
  const res = curationStatuses.filter(item => {
    return item.key === key;
  });
  return res.length > 0 ? res[0].value : key;
};

export const connectionTypes = ['OvertheAir', 'USBUpdate'];

// export const getConnectionType = () => {
//   // if (localStorage.tenantHandle === 'vce') {
//   //   return [
//   //     { title: 'OVER_THE_AIR', field: 'Over the air' },
//   //     { title: 'USB_UPDATE', field: 'Usb update' }
//   //   ];
//   // }
//   return [
//     { title: 'INFOTAINMENT', field: 'Infotainment' },
//     { title: 'PRODUCTIVITY', field: 'Productivity' },
//     { title: 'WORKSHOP', field: 'workshop' }
//   ];
// };

export const getConnectionType1 = () => {
  if (localStorage.tenantHandle === 'vce' || localStorage.tenantHandle === 'penta') {
    return [
      { title: 'OVER_THE_AIR', connectionTypes: 'Over the air' },
      { title: 'USB_UPDATE', connectionTypes: 'Usb update' }
    ];
  }
  return [
    { title: 'Infotainment', connectionTypes: 'INFOTAINMENT' },
    { title: 'Productivity', connectionTypes: 'PRODUCTIVITY' },
    { title: 'Workshop', connectionTypes: 'WORKSHOP' }
  ];
};

export const getConnectionType = key => {
  switch (key) {
    case 'OvertheAir':
      return 'Over the air';
    case 'USBUpdate':
      return 'USB update';
  }
};

export const getMetadataTypes = () => {
  if (localStorage.tenantHandle === 'am_kit_emea') {
    return ['HARDWARE', 'VEHICLE_REPOSITORY'];
  }
  return ['FIRMWARE', 'MAP', 'HARDWARE', 'VEHICLE_REPOSITORY'];
};

const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

const convertVceData = data =>
  data
    .map(d => {
      if (d === 'INFOTAINMENT') {
        d = 'Over the air';
      } else if (d === 'WORKSHOP') {
        d = 'Usb update';
      } else {
        d = 'Workshop';
      }
      return d;
    })
    .map(c => capitalize(c))
    .join(',');

export const capitalizeData = data => {
  if (localStorage.tenantHandle === 'vce' || localStorage.tenantHandle === 'penta') {
    return convertVceData(data);
  }
  return data && data !== null ? data.map(c => capitalize(c)).join(',') : '-';
};
export const connectionTypesOptions = () => {
  let data = [];
  const tenant = localStorage.tenantHandle;
  const semType = getSemDetails(); // localStorage.sem;
  if (tenant === 'vce' || tenant === 'penta') {
    data = [
      { title: 'Over the air', connectionTypes: 'INFOTAINMENT' },
      { title: 'Usb update', connectionTypes: 'WORKSHOP' }
    ];
  } else {
    data = [
      { title: 'Infotainment', connectionTypes: 'INFOTAINMENT' },
      { title: 'Productivity', connectionTypes: 'PRODUCTIVITY' },
      { title: 'Workshop', connectionTypes: 'WORKSHOP' }
    ];
  }
  return data;
};
