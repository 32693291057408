import { put, call } from 'redux-saga/effects';
import * as service from '../services/loginService';

import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SHOW_LOADING,
  HIDE_LOADING,
  SET_USER_ROLE,
  // REFRESH_CREDENTIALS,
  UPDATE_CREDENTIALS,
  SHOW_PAGE_LOADING,
  HIDE_PAGE_LOADING
} from '../constants';
import * as types from '../constants';

export function* login(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const loginResponse = yield call(service.login, payload);
    if (loginResponse.token === 'LOGIN_FAILED') {
      yield put({ type: LOGIN_ERROR });
      yield put({ type: HIDE_LOADING });
    } else {
      const validateTokenResponse = yield call(service.validateToken, loginResponse.token);
      const respDetails = {
        tenant: validateTokenResponse.tenant,
        semVal: validateTokenResponse.sem
      };
      const currentTenant = yield call(service.getCurrentTenant, respDetails);
      const inputParams = {
        userName: validateTokenResponse.username,
        tenant: validateTokenResponse.tenant
      };
      const getClaims = yield call(service.getClaims, inputParams);
      let claims = [];

      if (validateTokenResponse.tenant === 'vce') {
        claims = JSON.stringify(getClaims.serviceList);
        localStorage.setItem('claims', claims);
        yield put({ type: SET_USER_ROLE, role: getClaims.name });
      } else {
        claims = JSON.stringify(getClaims);
        localStorage.setItem('claims', claims);
        yield put({ type: SET_USER_ROLE, role: validateTokenResponse.roleName });
      }

      const response = {
        token: loginResponse.token,
        tokenValidation: validateTokenResponse,
        currentTenant,
        isLoggedIn: true,
        claims
      };

      yield put({ type: LOGIN_SUCCESS, response });
      yield put({ type: HIDE_LOADING });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    // yield put({ type: LOGIN_ERROR })
  }
}

export function* updateSemsClient(payload) {
  try {
    yield call(service.updateSemsClient, payload.data);
    const prefix = '/';
    if (window.location.href.indexOf('sems/applications') >= 0) {
      window.location.href = `#${prefix}sems/applications`;
      yield put({ type: types.FETCH_APPLICATIONS });
    } else if (window.location.href.indexOf('sems/firmwares') >= 0) {
      window.location.href = `#${prefix}sems/firmwares`;
      yield put({ type: types.FETCH_FIRMWARES });
    } else if (window.location.href.indexOf('sems/dashboard') >= 0) {
      window.location.href = `#${prefix}sems/dashboard`;
    } else if (window.location.href.indexOf('sems/maps') >= 0) {
      window.location.href = `#${prefix}sems/maps`;
      yield put({ type: types.FETCH_MAPS });
    } else if (window.location.href.indexOf('sems/sets') >= 0) {
      window.location.href = `#${prefix}sems/sets`;
      yield put({ type: types.FETCH_SETS });
    } else if (window.location.href.indexOf('sems/testsets') >= 0) {
      window.location.href = `#${prefix}sems/testsets`;
      yield put({ type: types.FETCH_TEST_SETS });
    } else if (window.location.href.indexOf('sems/metadatas') >= 0) {
      window.location.href = `#${prefix}sems/metadatas`;
      yield put({ type: types.FETCH_METADATAS });
    } else if (window.location.href.indexOf('sems/applications/') >= 0) {
      window.location.href = `${prefix}sems/applications`;
    } else if (window.location.href.indexOf('sems/export-to-usb') >= 0) {
      window.location.href = `#${prefix}sems/export-to-usb`;
    } else if (window.location.href.indexOf('sems/reports/business-reports') >= 0) {
      window.location.href = `#${prefix}sems/reports/business-reports`;
      yield put({ type: types.FETCH_BUSINESS_REPORTS });
    } else if (window.location.href.indexOf('sems/reports/data-threshold-report') >= 0) {
      window.location.href = `#${prefix}sems/reports/data-threshold-report`;
      yield put({ type: types.FETCH_DATA_THRESHOLD_REPORTS });
    } else if (window.location.href.indexOf('sems/reports/audit-log-reports') >= 0) {
      window.location.href = `#${prefix}sems/reports/audit-log-reports`;
    } else if (window.location.href.indexOf('sems/reports/denied-access-reports') >= 0) {
      window.location.href = `#${prefix}sems/reports/denied-access-reports`;
      yield put({ type: types.FETCH_DENIED_ACCESS_REPORTS });
    } else if (window.location.href.indexOf('sems/reports/installation-status-reports') >= 0) {
      window.location.href = `#${prefix}sems/reports/installation-status-reports`;
      yield put({ type: types.FETCH_INSTALLATION_STATUS_REPORTS });
    } else if (window.location.href.indexOf('sems/reports/vehicle-status-reports') >= 0) {
      window.location.href = `#${prefix}sems/reports/vehicle-status-reports`;
      yield put({ type: types.FETCH_VEHICLE_STATUS_REPORTS });
    } else if (window.location.href.indexOf('sems/reports/technical-reports') >= 0) {
      window.location.href = `#${prefix}sems/reports/technical-reports`;
    } else if (window.location.href.indexOf('sems/reports/ota-lock-reports') >= 0) {
      window.location.href = `#${prefix}sems/reports/ota-lock-reports`;
      yield put({ type: types.FETCH_OTA_LOCK_REPORTS });
    } else if (window.location.href.indexOf('sems/download-map') >= 0) {
      window.location.href = `#${prefix}sems/download-map`;
    } else if (window.location.href.indexOf('sems/user-management') >= 0) {
      window.location.href = `#${prefix}sems/user-management`;
      yield put({ type: types.FETCH_USERS });
      yield put({ type: types.FETCH_USER_ROLES });
    } else if (window.location.href.indexOf('sems/reports/software-update-criteria') >= 0) {
      window.location.href = `#${prefix}sems/reports/software-update-criteria`;
      yield put({ type: types.FETCH_SOFTWARE_UPDATE_CRITERIA });
    } else if (window.location.href.indexOf('sems/thresholdconfig') >= 0) {
      window.location.href = `#${prefix}sems/thresholdconfig`;
    } else if (window.location.href.indexOf('sems/reports/analysis-tool') >= 0) {
      window.location.href = `#${prefix}sems/reports/analysis-tool`;
    } else {
      window.location.reload();
    }
    // yield put({ type: UPDATE_SEMS_CLIENT_SUCCESS, response })
  } catch (error) {
    console.log(error);
  }
}

export function* refreshCredentials() {
  try {
    yield put({ type: SHOW_PAGE_LOADING });
    //const loginResponse = yield call(service.login, payload);
    // const token = localStorage.getItem('token');
    // if (token === 'LOGIN_FAILED') {
    //   yield put({ type: LOGIN_ERROR });
    //   yield put({ type: HIDE_PAGE_LOADING });
    // } else {
    //   const validateTokenResponse = yield call(service.validateToken, token);
    //   const currentTenant = yield call(service.getCurrentTenant, validateTokenResponse.tenant);
    //   const inputParams = {
    //     userName: validateTokenResponse.username,
    //     tenant: validateTokenResponse.tenant
    //   };
    //   const getClaims = yield call(service.getClaims, inputParams);
    //   let claims = [];

    //   if (validateTokenResponse.tenant == 'vce') {
    //     claims = JSON.stringify(getClaims.serviceList);
    //     localStorage.setItem('claims', claims);
    //     yield put({ type: SET_USER_ROLE, role: getClaims.name });
    //   } else {
    //     claims = JSON.stringify(getClaims);
    //     localStorage.setItem('claims', claims);
    //     yield put({ type: SET_USER_ROLE, role: validateTokenResponse.roleName });
    //   }

    //   const data = {
    //     token: token,
    //     tokenValidation: validateTokenResponse,
    //     currentTenant,
    //     isLoggedIn: true,
    //     claims
    //   };

    // }
    yield put({ type: UPDATE_CREDENTIALS });
    yield put({ type: HIDE_PAGE_LOADING });
  } catch (error) {
    yield put({ type: HIDE_PAGE_LOADING });
  }
}
