import {
  APPLICATIONS_LOADED,
  APPLICATION_LOADED,
  APPLICATION_VERSION_LOADED,
  AWS_S3_SEM2_CONFIG_LOADED,
  APPLICATIONS_LOADING_START,
  APPLICATIONS_LOADING_END,
  FETCH_APPLICATION,
  APPLICATION_VERSION_FAILED,
  APPLICATION_VERSION_LOADING_START,
  APPLICATION_VERSION_LOADING_END,
  CLEAR_APPLICATIONS_COMPONENT_DATA
  // AWS_S3_SEM2_CONFIG_LOADED
} from '../constants';
import { sort, sortVersions } from '../utils';

const initialState = {
  applications: [],
  application: undefined,
  applicationVersion: [],
  awsS3Config: {},
  loading: false,
  appVersionLoading: false
};

const applicationsReducer = (state = initialState, { type, response }) => {
  switch (type) {
    case APPLICATIONS_LOADED:
      sort(response, 'label');
      return { ...state, applications: response };

    case APPLICATION_LOADED:
      return { ...state, application: response };
    case CLEAR_APPLICATIONS_COMPONENT_DATA:
      return { ...state, application: undefined, applications: [] };

    case APPLICATION_VERSION_LOADED:
      sortVersions(response);
      if (response) {
        response.forEach(item => {
          item.rowId = `row_id_${new Date().getTime()}${item.id}`;
        });
      }
      return { ...state, applicationVersion: response };

    case AWS_S3_SEM2_CONFIG_LOADED:
      return { ...state, awsS3Config: response };

    case FETCH_APPLICATION:
      return { ...state, applicationVersion: [] };

    case APPLICATIONS_LOADING_START:
      return { ...state, loading: true };

    case APPLICATIONS_LOADING_END:
      return { ...state, loading: false };

    case APPLICATION_VERSION_FAILED:
      return { ...state, applicationVersion: [] };

    case APPLICATION_VERSION_LOADING_START:
      return { ...state, appVersionLoading: true };

    case APPLICATION_VERSION_LOADING_END:
      return { ...state, appVersionLoading: false };

    default:
      return state;
  }
};

export default applicationsReducer;
