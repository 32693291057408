import * as types from '../constants';

const initialState = {
  expressions: []
};

function expressionReducer(state = initialState, action) {
  switch (action.type) {
    case types.EXPRESSIONS_LOADED:
      return { ...state, expressions: action.response };
    default:
      return state;
  }
}

export default expressionReducer;
