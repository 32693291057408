import * as types from '../constants';

const initialState = {
  reports: [],
  loading: false
};

function deniedAccessReportReducer(state = initialState, action) {
  switch (action.type) {
    case types.DENIED_ACCESS_REPORTS_LOADED:
      return { ...state, reports: action.response };
    case types.DENIED_ACCESS_REPORTS_LOADING_START:
      return { ...state, loading: true };
    case types.DENIED_ACCESS_REPORTS_LOADING_END:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default deniedAccessReportReducer;
