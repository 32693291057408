import { put, call } from 'redux-saga/effects';
import {
  getMaps,
  getMap,
  getMapVersion,
  create,
  deleteMap as deleteItem,
  getMapNamesFormUpload
} from '../services/mapService';
import {
  MAPS_LOADED,
  MAPS_FAILED,
  SHOW_LOADING,
  HIDE_LOADING,
  MAP_VERSION_FAILED,
  MAP_VERSION_LOADED,
  MAP_LOADED,
  MAP_FAILED,
  CREATE_MAP_FAILED,
  CREATE_MAP_LOADED,
  DELETE_MAP_FAILED,
  MAPNAMES_FOR_UPLOAD_LOADED,
  MAPNAMES_FOR_UPLOAD_FAILED,
  MAPS_LOADING_END,
  MAPS_LOADING_START,
  MAP_VERSION_LOADING_END,
  MAP_VERSION_LOADING_START
} from '../constants';

export function* fetchMaps() {
  try {
    yield put({ type: MAPS_LOADING_START });
    const response = yield call(getMaps);
    yield put({ type: MAPS_LOADED, response });
    yield put({ type: MAPS_LOADING_END });
  } catch (error) {
    yield put({ type: MAPS_FAILED, error });
    yield put({ type: MAPS_LOADING_END });
  }
}

export function* fetchMapNamesForUpload() {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getMapNamesFormUpload);
    yield put({ type: MAPNAMES_FOR_UPLOAD_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: MAPNAMES_FOR_UPLOAD_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* fetchMap(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getMap, payload.name);
    yield put({ type: MAP_LOADED, response });
  } catch (error) {
    yield put({ type: MAP_FAILED, error });
  }
}

export function* fetchMapVersion(payload) {
  try {
    yield put({ type: MAP_VERSION_LOADING_START });
    const response = yield call(getMapVersion, payload.name);
    yield put({ type: MAP_VERSION_LOADED, response });
    yield put({ type: MAP_VERSION_LOADING_END });
  } catch (error) {
    yield put({ type: MAP_VERSION_FAILED, error });
    yield put({ type: MAP_VERSION_LOADING_END });
  }
}

export function* createMap(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const createResponse = yield call(create, payload.map);
    const response = yield call(getMaps);
    yield put({ type: MAPS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: CREATE_MAP_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}

export function* deleteMap(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const deleteResponse = yield call(deleteItem, payload.name);
    const response = yield call(getMaps);
    yield put({ type: MAPS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: DELETE_MAP_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}
