import * as types from '../constants';

const initialState = {
  mapData: [],
  loading: false,
  region: ''
};

function downloadMapReducer(state = initialState, { type, response }) {
  switch (type) {
    case types.DOWNLOAD_MAPS_LOADED:
      return { ...state, mapData: response };
    case types.DOWNLOAD_MAP_SHOW_LOADING:
      return { ...state, loading: true };
    case types.DOWNLOAD_MAP_HIDE_LOADING:
      return { ...state, loading: false };
    case types.REGION:
      return { ...state, region: response };
    default:
      return state;
  }
}

export default downloadMapReducer;
