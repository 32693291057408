import * as types from '../constants';

function appendSoftwareDetailsData(appendSoftwareDetailsData, clonedState) {
  let dataList = [...clonedState.analysisReportData];
  const dataListLen = dataList.length;
  for (var index = 0; index < dataListLen; index++) {
    if (appendSoftwareDetailsData.primaryId === dataList[index].primaryId) {
      dataList[index]['vehicleSoftwareDetails'] = appendSoftwareDetailsData;
    }
  }
  return { ...clonedState, analysisReportData: dataList };
}

function combineVehicalMetaData(allMetaInfo) {
  let allMetaData = [];
  const keys = Object.keys(allMetaInfo);
  keys.forEach(key => {
    if (allMetaInfo[key] !== null && key !== 'metadata') {
      if (key === 'metadataValues') {
        allMetaData = [...allMetaData, ...allMetaInfo[key]];
      } else {
        allMetaData.push({ metadataName: key, value: allMetaInfo[key] });
      }
    }
  });
  return allMetaData;
}
const initialState = {
  analysisReportData: null,
  analysisReportApiFailed: false,
  analysisReportApiCallInProgress: false,
  isApiLoading: false
};

const analysisToolReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ANALYSIS_TOOL_REPORTS_LOADED:
      const contructedData = action.response;
      if (contructedData && contructedData.length > 0) {
        contructedData.map(vehicleData => {
          return (vehicleData['vehicleAllMetaInfo'] = combineVehicalMetaData(vehicleData));
        });
      }
      return {
        ...state,
        analysisReportData: contructedData,
        isApiLoading: false
      };
    case types.INSERT_ANALYSIS_TOOL_REPORT:
      const fileData = action.data;
      return {
        ...state,
        analysisReportData: fileData,
        isApiLoading: false
      };

    case types.ANALYSIS_REPORT_API_LOADING:
      return { ...state, isApiLoading: true };
    case types.VEHICLE_SOFTWARE_DETAILS_LOADED:
      const newState = appendSoftwareDetailsData(action.response, { ...state });
      return newState;
    case types.ANALYSIS_TOOL_REPORTS_FAILED:
      return { ...initialState };
    case types.CLEAR_ANALYSIS_REPORT_DATA:
      return { ...initialState };
    default:
      return state;
  }
};

export default analysisToolReportReducer;
