import * as types from '../constants';
import { sort } from '../utils';

const initialState = {
  metaDatas: [],
  metaData: undefined,
  values: [],
  uninitializedValues: [],
  allMetaDatas: [],
  allMetaDataValues: [],
  loading: false,
  metaDataValueLoading: false
};

function metaDataReducer(state = initialState, action) {
  switch (action.type) {
    case types.METADATAS_LOADED:
      sort(action.response, 'name');
      return { ...state, metaDatas: action.response };
    case types.METADATA_VALUES_LOADED:
      sort(action.response, 'sortIndex');
      return { ...state, values: action.response };
    case types.ALL_METADATAS_LOADED:
      sort(action.response, 'name');
      return { ...state, allMetaDatas: action.response };
    case types.ALL_METADATA_VALUES_LOADED:
      sort(action.response, 'sortIndex');
      return { ...state, allMetaDataValues: action.response };
    case types.METADATA_UNINITIALIZED_VALUES_LOADED:
      sort(action.response, 'value');
      return { ...state, uninitializedValues: action.response };
    case types.METADATAS_LOADING_START:
      return { ...state, loading: true };
    case types.METADATAS_LOADING_END:
      return { ...state, loading: false };
    case types.METADATA_VALUES_LOADING_START:
      return { ...state, metaDataValueLoading: true };
    case types.METADATA_VALUES_LOADING_END:
      return { ...state, metaDataValueLoading: false };
    case types.UPDATE_UNINITIALIZED_VALUES:
      const updated_values = state.uninitializedValues.filter(
        item => item.value !== action.response
      );
      return { ...state, uninitializedValues: updated_values };
    default:
      return state;
  }
}

export default metaDataReducer;
