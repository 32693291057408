import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const customStyles = {
  root: {
    position: 'relative'
  },
  top: {
    color: '#eef3fd'
  },
  bottom: {
    left: '10px',
    color: '#0047cafc',
    position: 'relative'
  }
};

function MiniSpinner(props) {
  return (
    <div style={customStyles.root}>
      <CircularProgress style={customStyles.bottom} size={14} {...props} />
    </div>
  );
}

export default MiniSpinner;
