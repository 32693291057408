import * as types from '../constants';

const initialState = {
  reports: []
};

function otaLockReducer(state = initialState, action) {
  switch (action.type) {
    case types.OTA_LOCK_REPORTS_LOADED:
      return { ...state, reports: action.response };
    default:
      return state;
  }
}

export default otaLockReducer;
