import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createHashHistory } from 'history';

import rootReducer from '../reducers';
import rootSaga from '../sagas';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createHashHistory();
const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  return {
    // ...createStore(rootReducer(history), storeEnhancers(applyMiddleware(sagaMiddleware))),
    ...createStore(rootReducer, storeEnhancers(applyMiddleware(sagaMiddleware))),
    runSaga: sagaMiddleware.run(rootSaga)
  };
};

export default configureStore;
