import { put, call } from 'redux-saga/effects';
import getVehicleStatus from '../services/vehicleStatusService';
import {
  SHOW_LOADING,
  HIDE_LOADING,
  FETCH_VEHICLE_STATUS_REPORTS_LOADED,
  FETCH_VEHICLE_STATUS_REPORTS_FAILED
} from '../constants';

export default function* fetchVehicleStatusReport() {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getVehicleStatus);
    yield put({ type: FETCH_VEHICLE_STATUS_REPORTS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: FETCH_VEHICLE_STATUS_REPORTS_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}
