import {
  LOGIN,
  LOGOUT,
  REFRESH_CREDENTIALS,
  UPDATE_SEMS_CLIENT,
  UPDATE_SEMS_TYPE
} from '../constants';

export const login = user => {
  return {
    type: LOGIN,
    user
  };
};

export const logout = (payload = '') => {
  return {
    type: LOGOUT,
    payload
  };
};

export const updateSemsClient = data => {
  return {
    type: UPDATE_SEMS_CLIENT,
    data
  };
};

export const updateSems = data => {
  return {
    type: UPDATE_SEMS_TYPE,
    data
  };
};

export const refreshCredentials = () => {
  return {
    type: REFRESH_CREDENTIALS
  };
};
