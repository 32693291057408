import { store } from '../..';
import { getSemDetails, getSemTypeDetails } from '../../services/apis';

export const getApiHeaderConfig = propsType => {
  let configObj = {};
  const newState = store.getState();
  const tenant = newState.loginReducer.tenant.handle || localStorage.getItem('tenantHandle');
  const userName = newState.loginReducer.user.username || localStorage.getItem('user');
  const semClient =
    localStorage.getItem('tenantHandle') !== 'vce' ? getSemDetails() : getSemTypeDetails();
  //const tenant = localStorage.getItem('tenantHandle');
  //const userName = localStorage.getItem('user');
  switch (propsType) {
    case 'userName':
      configObj['headers'] = { userName };
      break;
    case 'userName&tenant':
      configObj['headers'] = {
        userName,
        tenant
      };
      break;
      case 'userName&semClient':
      configObj['headers'] = {
        userName,
        semClient
      };
      break;
    case 'semClient&tenant':
      configObj['headers'] = {
        semClient,
        tenant
      };
      break;
      case 'semClient&tenant&userName':
      configObj['headers'] = {
        semClient,
        tenant,
        userName
      };
      break;
    case 'semClient':
      configObj['headers'] = {
        semClient
      };
      break;
    default:
      break;
  }
  return configObj;
};
