import ThemeProvider from '@mui/material/styles/ThemeProvider';
import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import Themes from './themes';

export const store = configureStore();

// ReactDOM.render(
//   <MuiThemeProvider theme={Themes.default}>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </MuiThemeProvider>,

//   document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={Themes.default}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
