import { store } from '..';
//As present scenerion please dont pass semType to appendTenant

export const appendTenant = (baseUrl, params = []) => {
  const newState = store.getState();
  let loginData = {};
  if (!(Object.keys(newState.loginReducer.user).length === 0)) {
    loginData = {
      tenant: newState.loginReducer.tenant.handle || localStorage.getItem('tenantHandle'),
      semType: newState.loginReducer.semType || localStorage.getItem('semType'),
      userName: newState.loginReducer.user.username || localStorage.getItem('user'),
      userId: newState.loginReducer.user.useridentity || localStorage.getItem('userId'),
      currentUser: newState.loginReducer.user.username || localStorage.getItem('user')
    };
  } else {
    loginData = {
      tenant: localStorage.getItem('tenantHandle') || '',
      semType: localStorage.getItem('semType') || '',
      userName: localStorage.getItem('user') || '',
      userId: localStorage.getItem('userId') || '',
      currentUser: localStorage.getItem('user') || ''
    };
  }

  //console.log(loginData);
  if (params.length === 0) {
    return baseUrl;
  }
  const apiParams = params.map(param => `${param}=${loginData[param]}`);
  const appendUrl = apiParams.join('&');
  return baseUrl + '?' + appendUrl;
};
