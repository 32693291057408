import * as types from '../constants';

const initialState = {
  data: [],
  isFailed: false,
  isLoading: false
};

function softwareUpdateCriteriaReducer(state = initialState, action) {
  switch (action.type) {
    case types.SOFTWARE_UPDATE_CRITERIA_LOADED:
      return {
        ...state,
        data: action.response,
        isLoading: false
      };

    case types.SOFTWARE_UPDATE_CRITERIA_LOADING_START:
      return {
        ...state,
        isLoading: true
      };

    case types.SOFTWARE_UPDATE_CRITERIA_LOADING_FAILED:
      return {
        ...state,
        isFailed: true,
        isLoading: false
      };

    default:
      return state;
  }
}

export default softwareUpdateCriteriaReducer;
