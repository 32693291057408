import * as types from '../constants';
import { sort, sortVersions } from '../utils';

const initialState = {
  maps: [],
  map: undefined,
  mapVersion: [],
  mapNamesForUpload: [],
  technicalReports:{123456:{records:[{}]}, Red123:{records:[{}]}},
  loading: false,
  mapVersionLoading: false
};

function mapReducer(state = initialState, action) {
  switch (action.type) {
    case types.MAPS_LOADED:
      sort(action.response, 'name');
      return { ...state, maps: action.response };
    case types.MAPNAMES_FOR_UPLOAD_LOADED:
      return { ...state, mapNamesForUpload: action.response };
    case types.MAP_LOADED:
      return { ...state, map: action.response };
    case types.MAP_VERSION_LOADED:
      sortVersions(action.response);
      if (action.response) {
        action.response.forEach(item => {
          item.rowId = `row_id_${new Date().getTime()}${item.id}`;
        });
      }
      return { ...state, mapVersion: action.response };
    case types.FETCH_MAP:
      return { ...state, mapVersion: [] };
    case types.MAP_VERSION_FAILED:
      return { ...state, mapVersion: [] };

    case types.MAPS_LOADING_START:
      return { ...state, loading: true };

    case types.MAPS_LOADING_END:
      return { ...state, loading: false };

    case types.MAP_VERSION_LOADING_START:
      return { ...state, mapVersionLoading: true };

    case types.MAP_VERSION_LOADING_END:
      return { ...state, mapVersionLoading: false };
    default:
      return state;
  }
}

export default mapReducer;
