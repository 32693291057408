import { put, call } from 'redux-saga/effects';
import getExpressions from '../services/expressionService';
import { SHOW_LOADING, HIDE_LOADING, EXPRESSIONS_LOADED, EXPRESSIONS_FAILED } from '../constants';

export function* fetchExpressions(payload) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getExpressions, payload.version);
    yield put({ type: EXPRESSIONS_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: EXPRESSIONS_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}
