import { put, call } from 'redux-saga/effects';
import { getUsers, getUserRoles } from '../services/userService';
import {
  USERS_LOADED,
  USERS_FAILED,
  USER_ROLES_LOADED,
  USER_ROLES_FAILED,
  SHOW_LOADING,
  HIDE_LOADING,
  USERS_LOADING_START,
  USERS_LOADING_END
} from '../constants';

export function* fetchUsers() {
  try {
    yield put({ type: USERS_LOADING_START });
    const response = yield call(getUsers);
    yield put({ type: USERS_LOADED, response });
    yield put({ type: USERS_LOADING_END });
  } catch (error) {
    yield put({ type: USERS_FAILED, error });
    yield put({ type: USERS_LOADING_END });
  }
}

export function* fetchUserRoles() {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getUserRoles);
    yield put({ type: USER_ROLES_LOADED, response });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    yield put({ type: USER_ROLES_FAILED, error });
    yield put({ type: HIDE_LOADING });
  }
}
